import { useMemo, useState, useEffect } from "react";
import { ApolloClient, InMemoryCache, gql, HttpLink } from "@apollo/client";
import { chain, sumBy, sortBy, maxBy, minBy } from "lodash";
import fetch from "cross-fetch";
import * as ethers from "ethers";
import moment from "moment";
import {
  setMulticallAddress,
  Contract,
  ContractCall,
  Provider,
} from "ethers-multicall";

import { fillPeriods } from "./helpers";
import { addresses, getAddress, PULSE } from "./addresses";
import {
  getPositionsClient,
  getStatsClient,
  ensSubgraphClient,
  pnsSubgraphClient,
} from "./graph";

const BigNumber = ethers.BigNumber;
const formatUnits = ethers.utils.formatUnits;
const { JsonRpcProvider, WebSocketProvider } = ethers.providers;

import RewardReader from "../abis/RewardReader.json";
import PhamousUiDataProvider from "../abis/PhamousUiDataProvider.json";
import PhlpManager from "../abis/PhlpManager.json";
import Token from "../abis/v1/Token.json";

const providers = {
  pulse: new WebSocketProvider("wss://rpc.pulsechain.com"),
};

function getProvider(chainName) {
  if (!(chainName in providers)) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return providers[chainName];
}

function getChainId(chainName) {
  const chainId = {
    pulse: PULSE,
  }[chainName];
  if (!chainId) {
    throw new Error(`Unknown chain ${chainName}`);
  }
  return chainId;
}

const NOW_TS = parseInt(Date.now() / 1000);
const FIRST_DATE_TS = parseInt(+new Date(2023, 8, 16) / 1000);

function fillNa(arr) {
  const prevValues = {};
  let keys;
  if (arr.length > 0) {
    keys = Object.keys(arr[0]);
    delete keys.timestamp;
    delete keys.id;
  }

  for (const el of arr) {
    for (const key of keys) {
      if (!el[key]) {
        if (prevValues[key]) {
          el[key] = prevValues[key];
        }
      } else {
        prevValues[key] = el[key];
      }
    }
  }
  return arr;
}

export async function queryEarnData(chainName, account) {
  const provider = getProvider(chainName);
  const chainId = getChainId(chainName);
  const rewardReader = new ethers.Contract(
    getAddress(chainId, "RewardReader"),
    RewardReader.abi,
    provider
  );
  const phlpContract = new ethers.Contract(
    getAddress(chainId, "PHLP"),
    Token.abi,
    provider
  );
  const phlpManager = new ethers.Contract(
    getAddress(chainId, "PhlpManager"),
    PhlpManager.abi,
    provider
  );

  let depositTokens;
  let rewardTrackersForDepositBalances;
  let rewardTrackersForStakingInfo;

  if (chainId === PULSE) {
    // TODO: need to update
    depositTokens = [
      "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
      "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0x35247165119B69A40edD5304969560D0ef486921",
      "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    ];
    rewardTrackersForDepositBalances = [
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0x4e971a87900b931fF39d1Aad67697F49835400b6",
    ];
    rewardTrackersForStakingInfo = [
      "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
      "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
      "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
      "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
      "0x4e971a87900b931fF39d1Aad67697F49835400b6",
    ];
  }

  const [balances, stakingInfo, phlpTotalSupply, phlpAum, phamePrice] =
    await Promise.all([
      rewardReader.getDepositBalances(
        account,
        depositTokens,
        rewardTrackersForDepositBalances
      ),
      rewardReader
        .getStakingInfo(account, rewardTrackersForStakingInfo)
        .then((info) => {
          return rewardTrackersForStakingInfo.map((_, i) => {
            return info.slice(i * 5, (i + 1) * 5);
          });
        }),
      phlpContract.totalSupply(),
      phlpManager.getAumInUsdph(true),
      4.9055,
    ]);

  const phlpPrice = phlpAum / 1e18 / (phlpTotalSupply / 1e18);
  const now = new Date();

  return {
    PHLP: {
      stakedPHLP: balances[5] / 1e18,
      pendingETH: stakingInfo[4][0] / 1e18,
      pendingEsPHAME: stakingInfo[3][0] / 1e18,
      phlpPrice,
    },
    PHAME: {
      stakedPHAME: balances[0] / 1e18,
      stakedEsPHAME: balances[1] / 1e18,
      pendingETH: stakingInfo[2][0] / 1e18,
      pendingEsPHAME: stakingInfo[0][0] / 1e18,
      phamePrice,
    },
    timestamp: parseInt(now / 1000),
    datetime: now.toISOString(),
  };
}

export const tokenDecimals = {
  "0xa1077a294dde1b09bb078844df40758a5d0f9a27": 18, // WPLS
  "0x95b303987a60c71504d99aa1b13b4da07b0790ab": 18, // PLSX
  "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39": 8, // HEX
  // "0x3819f64f282bf135d62168c1e513280daf905e06": 9, // HDRN
  // "0xe0d1bd019665956945043c96499c6414cfc300a9": 8, // MAXI
  // "0x06450dee7fd2fb8e39061434babcfc05599a6fb8": 18, // XEN
  // "0x4f7fcdb511a25099f870ee57c77f7db2561ec9b6": 18, // LOAN
  "0x15d38573d2feeb82e7ad5187ab8c1d52810b1f07": 6, // USDC
  "0xefd766ccb38eaf1dfd701853bfce31359239f305": 18, // DAI
  "0x0cb6f5a34ad42ec934882a05265a7d5f59b51a2f": 6, // USDT
  "0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c": 18, // WETH
  "0xb17d901469b9208b17d916112988a3fed19b5ca1": 8, // WBTC
};

export const tokenSymbols = {
  // PULSE
  "0xa1077a294dde1b09bb078844df40758a5d0f9a27": "WPLS",
  "0x95b303987a60c71504d99aa1b13b4da07b0790ab": "PLSX",
  "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39": "HEX",
  // "0x3819f64f282bf135d62168c1e513280daf905e06": "HDRN",
  // "0xe0d1bd019665956945043c96499c6414cfc300a9": "MAXI",
  // "0x06450dee7fd2fb8e39061434babcfc05599a6fb8": "XEN",
  // "0x4f7fcdb511a25099f870ee57c77f7db2561ec9b6": "LOAN",
  "0x15d38573d2feeb82e7ad5187ab8c1d52810b1f07": "USDC",
  "0xefd766ccb38eaf1dfd701853bfce31359239f305": "DAI",
  "0x0cb6f5a34ad42ec934882a05265a7d5f59b51a2f": "USDT",
  "0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c": "WETH",
  "0xb17d901469b9208b17d916112988a3fed19b5ca1": "WBTC",
};

function getTokenDecimals(token) {
  return tokenDecimals[token] || 18;
}

const knownSwapSources = {
  pulse: {
    "0x9f7f325cf72cdbffc658d7d1c4268a75b2ec6f0d": "PHAME OrderBook",
    "0x71870d9d83baf6a061b91480e823671f2ffe9477": "PHAME Router",
    "0x107fd37cc51cbab0ed8651deddda7205558b796c": "PHAME Price Oracle",
    "0x84a84937d910fd04679b0a6ac216a3486d888e5e": "PHAME PositionManager",
    "0x2f1ddd8383ff177440843b8743ac87b4034914c0": "PHAME FastPriceFeed", // FastPriceFeed
    "0xe24e54be30c81e31ab4e7e79cb0f40f434a61fdc": "PHAME Keeper",
    "0xdae9dd3d1a52cfce9d5f2fac7fde164d500e50f7": "PulseX",
  },
};

const defaultFetcher = (url) => fetch(url).then((res) => res.json());
export function useRequest(url, defaultValue, fetcher = defaultFetcher) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url]);

  return [data, loading, error];
}

export function useCoingeckoPrices(symbol, { from = FIRST_DATE_TS } = {}) {
  // token ids https://api.coingecko.com/api/v3/coins
  const _symbol = {
    BTC: "bitcoin",
    ETH: "ethereum",
    PLS: "pulsechain",
    PLSX: "pulsex",
    HEX: "hex-pulsechain",
    USDC: "usd-coin",
    USDT: "tether",
    DAI: "dai",
  }[symbol];

  const now = Date.now() / 1000;
  const days = Math.ceil(now / 86400) - Math.ceil(from / 86400) - 1;

  const url = `https://api.coingecko.com/api/v3/coins/${_symbol}/market_chart?vs_currency=usd&days=${days}&interval=daily`;

  const [res, loading, error] = useRequest(url);

  const data = useMemo(() => {
    if (!res || res.length === 0) {
      return null;
    }

    const ret = res.prices.map((item) => {
      // -1 is for shifting to previous day
      // because CG uses first price of the day, but for PHLP we store last price of the day
      const timestamp = item[0] - 1;
      const groupTs = parseInt(timestamp / 1000 / 86400) * 86400;
      return {
        timestamp: groupTs,
        value: item[1],
      };
    });
    return ret;
  }, [res]);

  return [data, loading, error];
}

function getImpermanentLoss(change) {
  return (2 * Math.sqrt(change)) / (1 + change) - 1;
}

export function useGraph(
  querySource,
  { subgraph = null, subgraphUrl = null, chainName = "pulse" } = {}
) {
  const query = gql(querySource);

  if (!subgraphUrl) {
    subgraphUrl = "https://sub2.phatty.io/subgraphs/name/phamous-stats";
  }

  const client = new ApolloClient({
    link: new HttpLink({ uri: subgraphUrl, fetch }),
    cache: new InMemoryCache(),
  });
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
  }, [querySource, setLoading]);

  useEffect(() => {
    client
      .query({ query })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((ex) => {
        console.warn(
          "Subgraph request failed error: %s subgraphUrl: %s",
          ex.message,
          subgraphUrl
        );
        setError(ex);
        setLoading(false);
      });
  }, [querySource, setData, setError, setLoading]);

  return [data, loading, error];
}

export function useLastBlock(chainName = "pulse") {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    providers[chainName]
      .getBlock()
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return [data, loading, error];
}

export function useLastSubgraphBlock(chainName = "pulse") {
  const [data, loading, error] = useGraph(
    `{
    _meta {
      block {
        number
      }
    }
  }`,
    { chainName }
  );
  const [block, setBlock] = useState(null);

  useEffect(() => {
    if (!data) {
      return;
    }

    providers[chainName].getBlock(data._meta.block.number).then((block) => {
      setBlock(block);
    });
  }, [data, setBlock]);

  return [block, loading, error];
}

export function useTradersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const [closedPositionsData, loading, error] = useGraph(
    `{
    tradingStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      profit
      loss
      profitCumulative
      lossCumulative
      longOpenInterest
      shortOpenInterest
    }
  }`,
    { chainName }
  );
  const [feesData] = useFeesData({ from, to, chainName });
  const marginFeesByTs = useMemo(() => {
    if (!feesData) {
      return {};
    }

    let feesCumulative = 0;
    return feesData.reduce((memo, { timestamp, margin: fees }) => {
      feesCumulative += fees;
      memo[timestamp] = {
        fees,
        feesCumulative,
      };
      return memo;
    }, {});
  }, [feesData]);

  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;
  const data = closedPositionsData
    ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map(
        (dataItem) => {
          const longOpenInterest = dataItem.longOpenInterest / 1e30;
          const shortOpenInterest = dataItem.shortOpenInterest / 1e30;
          const openInterest = longOpenInterest + shortOpenInterest;

          // const fees = (marginFeesByTs[dataItem.timestamp]?.fees || 0)
          // const feesCumulative = (marginFeesByTs[dataItem.timestamp]?.feesCumulative || 0)

          const profit = dataItem.profit / 1e30;
          const loss = dataItem.loss / 1e30;
          const profitCumulative = dataItem.profitCumulative / 1e30;
          const lossCumulative = dataItem.lossCumulative / 1e30;
          const pnlCumulative = profitCumulative - lossCumulative;
          const pnl = profit - loss;
          currentProfitCumulative += profit;
          currentLossCumulative -= loss;
          currentPnlCumulative += pnl;
          return {
            longOpenInterest,
            shortOpenInterest,
            openInterest,
            profit,
            loss: -loss,
            profitCumulative,
            lossCumulative: -lossCumulative,
            pnl,
            pnlCumulative,
            timestamp: dataItem.timestamp,
            currentPnlCumulative,
            currentLossCumulative,
            currentProfitCumulative,
          };
        }
      )
    : null;

  if (data && data.length > 0) {
    const maxProfit = maxBy(data, (item) => item.profit)?.profit || 0;
    const maxLoss = minBy(data, (item) => item.loss)?.loss || 0;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl)?.pnl || 0;
    const minPnl = minBy(data, (item) => item.pnl)?.pnl || 0;
    const maxCurrentCumulativePnl =
      maxBy(data, (item) => item.currentPnlCumulative)?.currentPnlCumulative ||
      0;
    const minCurrentCumulativePnl =
      minBy(data, (item) => item.currentPnlCumulative)?.currentPnlCumulative ||
      0;

    const currentProfitCumulative =
      data[data.length - 1].currentProfitCumulative;
    const currentLossCumulative = data[data.length - 1].currentLossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(
        currentProfitCumulative,
        -currentLossCumulative
      ),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}

function getSwapSourcesFragment(skip = 0, from, to) {
  return `
    hourlyVolumeBySources(
      first: 1000
      skip: ${skip}
      orderBy: timestamp
      orderDirection: desc
      where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      timestamp
      source
      swap
    }
  `;
}
export function useSwapSources({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const query = `{
    a: ${getSwapSourcesFragment(0, from, to)}
    b: ${getSwapSourcesFragment(1000, from, to)}
    c: ${getSwapSourcesFragment(2000, from, to)}
    d: ${getSwapSourcesFragment(3000, from, to)}
    e: ${getSwapSourcesFragment(4000, from, to)}
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  let data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const { a, b, c, d, e } = graphData;
    const all = [...a, ...b, ...c, ...d, ...e];

    const totalVolumeBySource = a.reduce((acc, item) => {
      const source = knownSwapSources[chainName][item.source] || item.source;
      if (!acc[source]) {
        acc[source] = 0;
      }
      acc[source] += item.swap / 1e30;
      return acc;
    }, {});
    const topVolumeSources = new Set(
      Object.entries(totalVolumeBySource)
        .sort((a, b) => b[1] - a[1])
        .map((item) => item[0])
        .slice(0, 30)
    );

    let ret = chain(all)
      .groupBy((item) => parseInt(item.timestamp / 86400) * 86400)
      .map((values, timestamp) => {
        let all = 0;
        const retItem = {
          timestamp: Number(timestamp),
          ...values.reduce((memo, item) => {
            let source =
              knownSwapSources[chainName][item.source] || item.source;
            if (!topVolumeSources.has(source)) {
              source = "Other";
            }
            if (item.swap != 0) {
              const volume = item.swap / 1e30;
              memo[source] = memo[source] || 0;
              memo[source] += volume;
              all += volume;
            }
            return memo;
          }, {}),
        };

        retItem.all = all;

        return retItem;
      })
      .sortBy((item) => item.timestamp)
      .value();

    return ret;
  }, [graphData]);

  return [data, loading, error];
}

export function useTotalVolumeFromServer() {
  const [data, loading] = useRequest(
    "https://gmx-server-mainnet.uw.r.appspot.com/total_volume"
  );

  return useMemo(() => {
    if (!data) {
      return [data, loading];
    }

    const total = data.reduce((memo, item) => {
      return memo + parseInt(item.data.volume) / 1e30;
    }, 0);
    return [total, loading];
  }, [data, loading]);
}

function getServerHostname(chainName) {
  if (chainName == "avalanche") {
    return "gmx-avax-server.uc.r.appspot.com";
  }
  return "gmx-server-mainnet.uw.r.appspot.com";
}

export function useVolumeDataRequest(
  url,
  defaultValue,
  from,
  to,
  fetcher = defaultFetcher
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState(defaultValue);

  useEffect(async () => {
    try {
      setLoading(true);
      const data = await fetcher(url);
      setData(data);
    } catch (ex) {
      console.error(ex);
      setError(ex);
    }
    setLoading(false);
  }, [url, from, to]);

  return [data, loading, error];
}

export function useVolumeDataFromServer({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const [data, loading] = useVolumeDataRequest(
    `https://${getServerHostname(chainName)}/daily_volume`,
    null,
    from,
    to,
    async (url) => {
      let after;
      const ret = [];
      // eslint-disable-next-line no-constant-condition
      while (true) {
        const res = await (
          await fetch(url + (after ? `?after=${after}` : ""))
        ).json();
        if (res.length === 0) return ret;
        for (const item of res) {
          if (item.data.timestamp < from) {
            return ret;
          }
          ret.push(item);
        }
        after = res[res.length - 1].id;
      }
    }
  );

  const ret = useMemo(() => {
    if (!data) {
      return null;
    }

    const tmp = data.reduce((memo, item) => {
      const timestamp = item.data.timestamp;
      if (timestamp < from || timestamp > to) {
        return memo;
      }

      let type;
      if (item.data.action === "Swap") {
        type = "swap";
      } else if (item.data.action === "SellUSDPH") {
        type = "burn";
      } else if (item.data.action === "BuyUSDPH") {
        type = "mint";
      } else if (item.data.action.includes("LiquidatePosition")) {
        type = "liquidation";
      } else {
        type = "margin";
      }
      const volume = Number(item.data.volume) / 1e30;
      memo[timestamp] = memo[timestamp] || {};
      memo[timestamp][type] = memo[timestamp][type] || 0;
      memo[timestamp][type] += volume;
      return memo;
    }, {});

    let cumulative = 0;
    const cumulativeByTs = {};
    return Object.keys(tmp)
      .sort()
      .map((timestamp) => {
        const item = tmp[timestamp];
        let all = 0;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        PROPS.forEach((prop) => {
          if (item[prop]) all += item[prop];
        });
        cumulative += all;
        cumulativeByTs[timestamp] = cumulative;
        return {
          timestamp,
          all,
          cumulative,
          movingAverageAll,
          ...item,
        };
      });
  }, [data, from, to]);

  return [ret, loading];
}

export function useUsersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const query = `{
    userStats(
      first: 1000
      orderBy: timestamp
      orderDirection: desc
      where: { period: "daily", timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      uniqueCount
      uniqueSwapCount
      uniqueMarginCount
      uniqueMintBurnCount
      uniqueCountCumulative
      uniqueSwapCountCumulative
      uniqueMarginCountCumulative
      uniqueMintBurnCountCumulative
      actionCount
      actionSwapCount
      actionMarginCount
      actionMintBurnCount
      timestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const prevUniqueCountCumulative = {};
  let cumulativeNewUserCount = 0;
  const data = graphData
    ? sortBy(graphData.userStats, "timestamp").map((item) => {
        const newCountData = ["", "Swap", "Margin", "MintBurn"].reduce(
          (memo, type) => {
            memo[`new${type}Count`] = prevUniqueCountCumulative[type]
              ? item[`unique${type}CountCumulative`] -
                prevUniqueCountCumulative[type]
              : item[`unique${type}Count`];
            prevUniqueCountCumulative[type] =
              item[`unique${type}CountCumulative`];
            return memo;
          },
          {}
        );
        cumulativeNewUserCount += newCountData.newCount;
        const oldCount = item.uniqueCount - newCountData.newCount;
        const oldPercent = ((oldCount / item.uniqueCount) * 100).toFixed(1);
        return {
          all: item.uniqueCount,
          uniqueSum:
            item.uniqueSwapCount +
            item.uniqueMarginCount +
            item.uniqueMintBurnCount,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...newCountData,
          ...item,
        };
      })
    : null;

  return [data, loading, error];
}

export function useFundingRateData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const query = `{
    fundingRates(
      first: 1000,
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: "daily", id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id,
      token,
      timestamp,
      startFundingRate,
      startTimestamp,
      endFundingRate,
      endTimestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    const groups = graphData.fundingRates.reduce((memo, item) => {
      const symbol = tokenSymbols[item.token];
      if (symbol === "MIM") {
        return memo;
      }
      memo[item.timestamp] = memo[item.timestamp] || {
        timestamp: item.timestamp,
      };
      const group = memo[item.timestamp];
      const timeDelta =
        parseInt((item.endTimestamp - item.startTimestamp) / 3600) * 3600;

      let fundingRate = 0;
      if (item.endFundingRate && item.startFundingRate) {
        const fundingDelta = item.endFundingRate - item.startFundingRate;
        const divisor = timeDelta / 86400;
        fundingRate = (fundingDelta / divisor / 10000) * 365;
      }
      group[symbol] = fundingRate;
      return memo;
    }, {});

    return fillNa(sortBy(Object.values(groups), "timestamp"));
  }, [graphData]);

  return [data, loading, error];
}

const MOVING_AVERAGE_DAYS = 7;
const MOVING_AVERAGE_PERIOD = 86400 * MOVING_AVERAGE_DAYS;

export function useVolumeData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const timestampProp = "id";
  const query = `{
    volumeStats(
      first: 1000,
      orderBy: ${timestampProp},
      orderDirection: desc
      where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
      subgraphError: allow
    ) {
      ${timestampProp}
      ${PROPS.join("\n")}
    }
  }`;
  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.volumeStats, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = item[prop] / 1e30;
        all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return ret.map((item) => {
      cumulative += item.all;

      let movingAverageAll;
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD;
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll =
          (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      };
    });
  }, [graphData]);

  return [data, loading, error];
}

export function useFeesData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const PROPS = "margin liquidation swap mint burn".split(" ");
  const feesQuery = `{
    feeStats(
      first: 1000
      orderBy: id
      orderDirection: desc
      where: { period: daily, id_gte: ${from}, id_lte: ${to} }
      subgraphError: allow
    ) {
      id
      margin
      marginAndLiquidation
      swap
      mint
      burn
    }
  }`;
  let [feesData, loading, error] = useGraph(feesQuery, {
    chainName,
  });

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null;
    }

    let chartData = sortBy(feesData.feeStats, "id").map((item) => {
      const ret = { timestamp: item.timestamp || item.id };

      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = item[prop] / 1e30;
        }
      });

      ret.liquidation = item.marginAndLiquidation / 1e30 - item.margin / 1e30;
      ret.all = PROPS.reduce((memo, prop) => memo + ret[prop], 0);
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, "all");
        cumulative += all;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        const ret = {
          timestamp: Number(timestamp),
          all,
          cumulative,
          movingAverageAll,
        };
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop);
        });
        cumulativeByTs[timestamp] = cumulative;
        return ret;
      })
      .value()
      .filter((item) => item.timestamp >= from);
  }, [feesData]);

  return [feesChartData, loading, error];
}

export function useAumPerformanceData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  groupPeriod,
}) {
  const [feesData, feesLoading] = useFeesData({ from, to, groupPeriod });
  const [phlpData, phlpLoading] = usePhlpData({ from, to, groupPeriod });
  const [volumeData, volumeLoading] = useVolumeData({ from, to, groupPeriod });

  const dailyCoef = 86400 / groupPeriod;

  const data = useMemo(() => {
    if (!feesData || !phlpData || !volumeData) {
      return null;
    }

    const ret = feesData.map((feeItem, i) => {
      const phlpItem = phlpData[i];
      const volumeItem = volumeData[i];
      let apr =
        feeItem?.all && phlpItem?.aum
          ? (feeItem.all / phlpItem.aum) * 100 * 365 * dailyCoef
          : null;
      if (apr > 10000) {
        apr = null;
      }
      let usage =
        volumeItem?.all && phlpItem?.aum
          ? (volumeItem.all / phlpItem.aum) * 100 * dailyCoef
          : null;
      if (usage > 10000) {
        usage = null;
      }

      return {
        timestamp: feeItem.timestamp,
        apr,
        usage,
      };
    });
    const averageApr =
      ret.reduce((memo, item) => item.apr + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageApr = averageApr));
    const averageUsage =
      ret.reduce((memo, item) => item.usage + memo, 0) / ret.length;
    ret.forEach((item) => (item.averageUsage = averageUsage));
    return ret;
  }, [feesData, phlpData, volumeData]);

  return [data, feesLoading || phlpLoading || volumeLoading];
}

export function usePhlpData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const timestampProp = "id";
  const query = `{
    phlpStats(
      first: 1000
      orderBy: ${timestampProp}
      orderDirection: desc
      where: {
        period: daily
        ${timestampProp}_gte: ${from}
        ${timestampProp}_lte: ${to}
      }
      subgraphError: allow
    ) {
      ${timestampProp}
      aumInUsdph
      phlpSupply
      distributedUsd
      distributedEth
    }
  }`;
  let [data, loading, error] = useGraph(query, { chainName });

  let cumulativeDistributedUsdPerPhlp = 0;
  let cumulativeDistributedEthPerPhlp = 0;
  const phlpChartData = useMemo(() => {
    if (!data) {
      return null;
    }

    let prevPhlpSupply;
    let prevAum;

    let ret = sortBy(data.phlpStats, (item) => item[timestampProp])
      .filter((item) => item[timestampProp] % 86400 === 0)
      .reduce((memo, item) => {
        const last = memo[memo.length - 1];

        const aum = Number(item.aumInUsdph) / 1e18;
        const phlpSupply = Number(item.phlpSupply) / 1e18;

        const distributedUsd = Number(item.distributedUsd) / 1e30;
        const distributedUsdPerPhlp = distributedUsd / phlpSupply || 0;
        cumulativeDistributedUsdPerPhlp += distributedUsdPerPhlp;

        const distributedEth = Number(item.distributedEth) / 1e18;
        const distributedEthPerPhlp = distributedEth / phlpSupply || 0;
        cumulativeDistributedEthPerPhlp += distributedEthPerPhlp;

        const phlpPrice = aum / phlpSupply;
        const timestamp = parseInt(item[timestampProp]);

        const newItem = {
          timestamp,
          aum,
          phlpSupply,
          phlpPrice,
          cumulativeDistributedEthPerPhlp,
          cumulativeDistributedUsdPerPhlp,
          distributedUsdPerPhlp,
          distributedEthPerPhlp,
        };

        if (last && last.timestamp === timestamp) {
          memo[memo.length - 1] = newItem;
        } else {
          memo.push(newItem);
        }

        return memo;
      }, [])
      .map((item) => {
        let { phlpSupply, aum } = item;
        if (!phlpSupply) {
          phlpSupply = prevPhlpSupply;
        }
        if (!aum) {
          aum = prevAum;
        }
        item.phlpSupplyChange = prevPhlpSupply
          ? ((phlpSupply - prevPhlpSupply) / prevPhlpSupply) * 100
          : 0;
        if (item.phlpSupplyChange > 1000) {
          item.phlpSupplyChange = 0;
        }
        item.aumChange = prevAum ? ((aum - prevAum) / prevAum) * 100 : 0;
        if (item.aumChange > 1000) {
          item.aumChange = 0;
        }
        prevPhlpSupply = phlpSupply;
        prevAum = aum;
        return item;
      });

    ret = fillNa(ret);
    return ret;
  }, [data]);

  return [phlpChartData, loading, error];
}

export function usePhlpPerformanceData(
  phlpData,
  feesData,
  { from = FIRST_DATE_TS, chainName = "pulse" } = {}
) {
  from = FIRST_DATE_TS > from ? FIRST_DATE_TS : from;
  const [btcPrices] = useCoingeckoPrices("BTC", { from });
  const [ethPrices] = useCoingeckoPrices("ETH", { from });
  const [plsPrices] = useCoingeckoPrices("PLS", { from });
  const [plsxPrices] = useCoingeckoPrices("PLSX", { from });
  const [hexPrices] = useCoingeckoPrices("HEX", { from });

  const phlpPerformanceChartData = useMemo(() => {
    if (
      !btcPrices ||
      !ethPrices ||
      !plsPrices ||
      !plsxPrices ||
      !hexPrices ||
      !phlpData ||
      !feesData ||
      phlpData.length == 0 ||
      feesData.length == 0
    ) {
      return null;
    }

    const phlpDataById = phlpData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    }, {});

    const feesDataById = feesData.reduce((memo, item) => {
      memo[item.timestamp] = item;
      return memo;
    });

    let BTC_WEIGHT = 0.03;
    let ETH_WEIGHT = 0.09;
    let PLS_WEIGHT = 0.25;
    let PLSX_WEIGHT = 0.09;
    let HEX_WEIGHT = 0.09;

    const STABLE_WEIGHT =
      1 - BTC_WEIGHT - ETH_WEIGHT - PLS_WEIGHT - PLSX_WEIGHT - HEX_WEIGHT;
    const PHLP_START_PRICE = phlpData[0]?.phlpPrice || 1.19;

    const btcFirstPrice = btcPrices[0]?.value;
    const ethFirstPrice = ethPrices[0]?.value;
    const plsFirstPrice = plsPrices[0]?.value;
    const plsxFirstPrice = plsxPrices[0]?.value;
    const hexFirstPrice = hexPrices[0]?.value;

    let indexBtcCount = (PHLP_START_PRICE * BTC_WEIGHT) / btcFirstPrice;
    let indexEthCount = (PHLP_START_PRICE * ETH_WEIGHT) / ethFirstPrice;
    let indexPlsCount = (PHLP_START_PRICE * PLS_WEIGHT) / plsFirstPrice;
    let indexPlsxCount = (PHLP_START_PRICE * PLSX_WEIGHT) / plsxFirstPrice;
    let indexHexCount = (PHLP_START_PRICE * HEX_WEIGHT) / hexFirstPrice;
    let indexStableCount = PHLP_START_PRICE * STABLE_WEIGHT;

    const lpBtcCount = (PHLP_START_PRICE * 0.5) / btcFirstPrice;
    const lpEthCount = (PHLP_START_PRICE * 0.5) / ethFirstPrice;
    const lpPlsCount = (PHLP_START_PRICE * 0.5) / plsFirstPrice;
    const lpPlsxCount = (PHLP_START_PRICE * 0.5) / plsxFirstPrice;
    const lpHexCount = (PHLP_START_PRICE * 0.5) / hexFirstPrice;

    const ret = [];
    let cumulativeFeesPerPhlp = 0;
    let lastPhlpItem;
    let lastFeesItem;

    let prevBtcPrice = 0;
    let prevEthPrice = 0;
    let prevPlsPrice = 0;
    let prevPlsxPrice = 0;
    let prevHexPrice = 0;

    let initBtcPrice = 0;
    let initEthPrice = 0;
    let initPlsPrice = 0;
    let initPlsxPrice = 0;
    let initHexPrice = 0;

    for (let i = 0; i < btcPrices.length; i++) {
      const btcPrice = btcPrices[i].value || prevBtcPrice;
      const ethPrice = ethPrices[i]?.value || prevEthPrice;
      const plsPrice = plsPrices[i]?.value || prevPlsPrice;
      const plsxPrice = plsxPrices[i]?.value || prevPlsxPrice;
      const hexPrice = hexPrices[i]?.value || prevHexPrice;
      prevEthPrice = ethPrice;
      prevPlsPrice = plsPrice;
      prevPlsxPrice = plsxPrice;
      prevHexPrice = hexPrice;

      const timestampGroup = parseInt(btcPrices[i].timestamp / 86400) * 86400;
      const phlpItem = phlpDataById[timestampGroup] || lastPhlpItem;
      lastPhlpItem = phlpItem;

      const phlpPrice = phlpItem?.phlpPrice;
      const phlpSupply = phlpItem?.phlpSupply;

      const feesItem = feesDataById[timestampGroup] || lastFeesItem;
      lastFeesItem = feesItem;

      const dailyFees = feesItem?.all;

      const syntheticPrice =
        indexBtcCount * btcPrice +
        indexEthCount * ethPrice +
        indexPlsCount * plsPrice +
        indexPlsxCount * plsxPrice +
        indexHexCount * hexPrice +
        indexStableCount;

      // rebalance each day. can rebalance each X days
      if (i % 1 == 0) {
        indexBtcCount = (syntheticPrice * BTC_WEIGHT) / btcPrice;
        indexEthCount = (syntheticPrice * ETH_WEIGHT) / ethPrice;
        indexPlsCount = (syntheticPrice * PLS_WEIGHT) / plsPrice;
        indexPlsxCount = (syntheticPrice * PLSX_WEIGHT) / plsxPrice;
        indexHexCount = (syntheticPrice * HEX_WEIGHT) / hexPrice;
        indexStableCount = syntheticPrice * STABLE_WEIGHT;
      }

      const lpBtcPrice =
        (lpBtcCount * btcPrice + PHLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(btcPrice / btcFirstPrice));
      const lpEthPrice =
        (lpEthCount * ethPrice + PHLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(ethPrice / ethFirstPrice));
      const lpPlsPrice =
        (lpPlsCount * plsPrice + PHLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(plsPrice / plsFirstPrice));
      const lpPlsxPrice =
        (lpPlsxCount * plsxPrice + PHLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(plsxPrice / plsxFirstPrice));
      const lpHexPrice =
        (lpHexCount * hexPrice + PHLP_START_PRICE / 2) *
        (1 + getImpermanentLoss(hexPrice / hexFirstPrice));

      if (dailyFees && phlpSupply) {
        const INCREASED_PHLP_REWARDS_TIMESTAMP = 1635714000;
        const PHLP_REWARDS_SHARE =
          timestampGroup >= INCREASED_PHLP_REWARDS_TIMESTAMP ? 0.7 : 0.5;
        const collectedFeesPerPhlp =
          (dailyFees / phlpSupply) * PHLP_REWARDS_SHARE;
        cumulativeFeesPerPhlp += collectedFeesPerPhlp;
      }

      let phlpPlusFees = phlpPrice;
      // if (phlpPrice && phlpSupply && cumulativeFeesPerPhlp) {
      //   phlpPlusFees = phlpPrice + cumulativeFeesPerPhlp;
      // }

      let phlpApr;
      let phlpPlusDistributedUsd;
      let phlpPlusDistributedEth;
      if (phlpItem) {
        if (phlpItem.cumulativeDistributedUsdPerPhlp) {
          phlpPlusDistributedUsd =
            phlpPrice + phlpItem.cumulativeDistributedUsdPerPhlp;
          // phlpApr = phlpItem.distributedUsdPerPhlp / phlpPrice * 365 * 100 // incorrect?
        }
        if (phlpItem.cumulativeDistributedEthPerPhlp) {
          phlpPlusDistributedEth =
            phlpPrice + phlpItem.cumulativeDistributedEthPerPhlp * ethPrice;
        }
      }

      if (initBtcPrice == 0) {
        initBtcPrice = btcPrice;
      }
      if (initEthPrice == 0) {
        initEthPrice = ethPrice;
      }
      if (initPlsPrice == 0) {
        initPlsPrice = plsPrice;
      }
      if (initPlsxPrice == 0) {
        initPlsxPrice = plsxPrice;
      }
      if (initHexPrice == 0) {
        initHexPrice = hexPrice;
      }

      ret.push({
        timestamp: btcPrices[i].timestamp,
        syntheticPrice,
        lpBtcPrice,
        lpEthPrice,
        lpPlsPrice,
        lpPlsxPrice,
        lpHexPrice,
        phlpPrice,
        btcPrice: initBtcPrice > 0 ? btcPrice / initBtcPrice : btcPrice,
        ethPrice: initEthPrice > 0 ? ethPrice / initEthPrice : ethPrice,
        plsPrice: initPlsPrice > 0 ? plsPrice / initPlsPrice : plsPrice,
        plsxPrice: initPlsxPrice > 0 ? plsxPrice / initPlsxPrice : plsxPrice,
        hexPrice: initHexPrice > 0 ? hexPrice / initHexPrice : hexPrice,
        btcPricePure: btcPrice,
        ethPricePure: ethPrice,
        plsPricePure: plsPrice,
        plsxPricePure: plsxPrice,
        hexPricePure: hexPrice,
        phlpPlusFees,
        phlpPlusDistributedUsd,
        phlpPlusDistributedEth,

        indexBtcCount,
        indexEthCount,
        indexPlsCount,
        indexPlsxCount,
        indexHexCount,
        indexStableCount,

        BTC_WEIGHT,
        ETH_WEIGHT,
        PLS_WEIGHT,
        PLSX_WEIGHT,
        HEX_WEIGHT,
        STABLE_WEIGHT,

        performanceLpEth: ((phlpPrice / lpEthPrice) * 100).toFixed(2),
        performanceLpEthCollectedFees: (
          (phlpPlusFees / lpEthPrice) *
          100
        ).toFixed(2),
        performanceLpEthDistributedUsd: (
          (phlpPlusDistributedUsd / lpEthPrice) *
          100
        ).toFixed(2),
        performanceLpEthDistributedEth: (
          (phlpPlusDistributedEth / lpEthPrice) *
          100
        ).toFixed(2),

        performanceLpBtcCollectedFees: (
          (phlpPlusFees / lpBtcPrice) *
          100
        ).toFixed(2),
        performanceLpPlsCollectedFees: (
          (phlpPlusFees / lpPlsPrice) *
          100
        ).toFixed(2),
        performanceLpPlsxCollectedFees: (
          (phlpPlusFees / lpPlsxPrice) *
          100
        ).toFixed(2),
        performanceLpHexCollectedFees: (
          (phlpPlusFees / lpHexPrice) *
          100
        ).toFixed(2),

        performanceSynthetic: ((phlpPrice / syntheticPrice) * 100).toFixed(2),
        performanceSyntheticCollectedFees: (
          (phlpPlusFees / syntheticPrice) *
          100
        ).toFixed(2),
        performanceSyntheticDistributedUsd: (
          (phlpPlusDistributedUsd / syntheticPrice) *
          100
        ).toFixed(2),
        performanceSyntheticDistributedEth: (
          (phlpPlusDistributedEth / syntheticPrice) *
          100
        ).toFixed(2),

        phlpApr,
      });
    }

    return ret;
  }, [btcPrices, ethPrices, phlpData, feesData]);

  return [phlpPerformanceChartData];
}

export function useTokenStats({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  period = "daily",
  chainName = "pulse",
} = {}) {
  const getTokenStatsFragment = ({ skip = 0 } = {}) => `
    tokenStats(
      first: 1000,
      skip: ${skip},
      orderBy: timestamp,
      orderDirection: desc,
      where: { period: ${period}, timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      poolAmountUsd
      timestamp
      token
    }
  `;

  // Request more than 1000 records to retrieve maximum stats for period
  const query = `{
    a: ${getTokenStatsFragment()}
    b: ${getTokenStatsFragment({ skip: 1000 })},
    c: ${getTokenStatsFragment({ skip: 2000 })},
    d: ${getTokenStatsFragment({ skip: 3000 })},
    e: ${getTokenStatsFragment({ skip: 4000 })},
    f: ${getTokenStatsFragment({ skip: 5000 })},
  }`;

  const [graphData, loading, error] = useGraph(query, { chainName });

  const data = useMemo(() => {
    if (loading || !graphData) {
      return null;
    }

    const fullData = Object.values(graphData).reduce((memo, records) => {
      memo.push(...records);
      return memo;
    }, []);

    const retrievedTokens = new Set();

    const timestampGroups = fullData.reduce((memo, item) => {
      const { timestamp, token, ...stats } = item;

      const symbol = tokenSymbols[token] || token;

      retrievedTokens.add(symbol);

      memo[timestamp] = memo[timestamp || 0] || {};

      memo[timestamp][symbol] = {
        poolAmountUsd: parseInt(stats.poolAmountUsd) / 1e30,
      };

      return memo;
    }, {});

    const timestamps = Object.keys(timestampGroups);
    for (let i = 0; i < timestamps.length - 1; i++) {
      const symbols = Array.from(retrievedTokens);
      for (let j = 0; j < symbols.length; j++) {
        if (
          timestampGroups[timestamps[i]][symbols[j]] &&
          !timestampGroups[timestamps[i + 1]][symbols[j]]?.poolAmountUsd
        ) {
          timestampGroups[timestamps[i + 1]][symbols[j]] =
            timestampGroups[timestamps[i]][symbols[j]];
        }
      }
    }

    const poolAmountUsdRecords = [];

    Object.entries(timestampGroups).forEach(([timestamp, dataItem]) => {
      const poolAmountUsdRecord = Object.entries(dataItem).reduce(
        (memo, [token, stats]) => {
          memo.all += stats.poolAmountUsd;
          memo[token] = stats.poolAmountUsd;
          memo.timestamp = timestamp;

          return memo;
        },
        { all: 0 }
      );

      poolAmountUsdRecords.push(poolAmountUsdRecord);
    });

    return {
      poolAmountUsd: poolAmountUsdRecords,
      tokenSymbols: Array.from(retrievedTokens),
    };
  }, [graphData, loading]);

  return [data, loading, error];
}

function combineXnsName(...xnsNames) {
  return xnsNames.filter(Boolean).join(" | ");
}

export async function getAllTradersXnsNames(users) {
  const result = {};
  if (!users.length) {
    return result;
  }
  const [ensNames, pnsNames] = await Promise.all([
    getEnsNames(users),
    getPnsNames(users),
  ]);
  for (let i = 0; i < users.length; i++) {
    const user = users[i]
    if (ensNames[i] || pnsNames[i]) {
      result[user] = {
        ens: ensNames[i],
        pns: pnsNames[i],
        xns: combineXnsName(ensNames[i], pnsNames[i]),
      };
    } else {
      result[user] = {};
    }
  }
  return result;
}

export function useAllTradersXnsNames({ users, chainName = "pulse" } = {}) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (users.length) {
      getAllTradersXnsNames(users).then((ensNames) => {
        setData(ensNames);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [users, chainName]);
  return [data, loading];
}

let multicallProviders = {};

export const getMulticallProvider = (provider, chainId) => {
  if (!multicallProviders[chainId]) {
    if (chainId === 369) {
      setMulticallAddress(
        chainId,
        "0x5ba1e12693dc8f9c48aad8770482f4739beed696"
      );
    }
    multicallProviders[chainId] = new Provider(provider, chainId);
  }

  return multicallProviders[chainId];
};

export async function getUserPositions(chainName = "pulse") {
  const allPositions = [];
  const count = 1000;

  let skip = 0;
  // eslint-disable-next-line no-constant-condition
  while (1) {
    const positionClient = getPositionsClient(PULSE);
    const { data } = await positionClient.query({
      query: gql(`
      {
        availablePositions (
          first: ${count}
          skip: ${skip}
        ) {
          id
          account
          collateralToken
          indexToken
          isLong
          size
          collateral
          averagePrice
          entryFundingRate
          reserveAmount
          realisedPnl
          lastIncreasedTime
          logIndex
          timestamp
        }
      }
    `),
    });

    const positions = data.availablePositions;

    for (let i = 0; i < positions.length; i++) {
      allPositions.push(positions[i]);
    }

    if (positions.length < count) {
      break;
    }
    skip += count;
  }

  const provider = getProvider(chainName);
  const chainId = getChainId(chainName);
  const multicall = getMulticallProvider(provider, chainId);
  const uiDataContract = new Contract(
    getAddress(chainId, "PhamousUiDataProvider"),
    PhamousUiDataProvider.abi
  );
  const getPosition = async (dataItem) => {
    const { account, indexToken, collateralToken, isLong } = dataItem;
    const [positionData, fundingData, vaultTokenInfo] = await multicall.all([
      uiDataContract.getPositions(
        getAddress(chainId, "AddressesProvider"),
        account,
        [collateralToken],
        [indexToken],
        [isLong]
      ),
      uiDataContract.getFundingRates(
        getAddress(chainId, "AddressesProvider"),
        "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
        [collateralToken]
      ),
      uiDataContract.getVaultTokenInfoV4(
        getAddress(chainId, "AddressesProvider"),
        "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
        ethers.utils.parseEther("1"),
        [indexToken]
      ),
    ]);

    const position = {
      account,
      collateralToken,
      indexToken,
      isLong,
      size: positionData[0],
      collateral: positionData[1],
      averagePrice: positionData[2],
      entryFundingRate: positionData[3],
      hasRealisedProfit: positionData[4].eq(1),
      realisedPnl: positionData[5],
      lastIncreasedTime: positionData[6].toNumber(),
      hasProfit: positionData[7].eq(1),
      delta: positionData[8],
      fundingRate: fundingData[0],
      cumulativeFundingRate: fundingData[1],
      collateralMinPrice: vaultTokenInfo[10],
      collateralMaxPrice: vaultTokenInfo[11],
    };

    position.fundingFee = position.size
      .mul(position.cumulativeFundingRate.sub(position.entryFundingRate))
      .div(1000000);
    position.collateralAfterFee = position.collateral.sub(position.fundingFee);

    const MARGIN_FEE_BASIS_POINTS = 47;
    const BASIS_POINTS_DIVISOR = 10000;
    position.closingFee = position.size
      .mul(MARGIN_FEE_BASIS_POINTS)
      .div(BASIS_POINTS_DIVISOR);
    position.positionFee = position.size
      .mul(MARGIN_FEE_BASIS_POINTS)
      .mul(2)
      .div(BASIS_POINTS_DIVISOR);
    position.totalFees = position.positionFee.add(position.fundingFee);

    (position.markPrice = position.isLong
      ? position.collateralMinPrice
      : position.collateralMaxPrice),
      (position.collateralAfterFee = position.collateral.sub(
        position.fundingFee
      ));
    position.pendingDelta = position.delta;
    if (position.collateral.gt(0)) {
      position.hasLowCollateral =
        position.collateralAfterFee.lt(0) ||
        position.size.div(position.collateralAfterFee.abs()).gt(50);

      if (position.averagePrice && position.markPrice) {
        const priceDelta = position.averagePrice.gt(position.markPrice)
          ? position.averagePrice.sub(position.markPrice)
          : position.markPrice.sub(position.averagePrice);
        position.pendingDelta = position.size
          .mul(priceDelta)
          .div(position.averagePrice);

        position.delta = position.pendingDelta;

        if (position.isLong) {
          position.hasProfit = position.markPrice.gte(position.averagePrice);
        } else {
          position.hasProfit = position.markPrice.lte(position.averagePrice);
        }
      }

      let hasProfitAfterFees;
      let pendingDeltaAfterFees;

      if (position.hasProfit) {
        if (position.pendingDelta?.gt(position.totalFees)) {
          hasProfitAfterFees = true;
          pendingDeltaAfterFees = position.pendingDelta.sub(position.totalFees);
        } else {
          hasProfitAfterFees = false;
          pendingDeltaAfterFees = position.totalFees.sub(position.pendingDelta);
        }
      } else {
        hasProfitAfterFees = false;
        pendingDeltaAfterFees = position.pendingDelta?.add(position.totalFees);
      }
      position.hasProfitAfterFees = hasProfitAfterFees;
      position.pendingDeltaAfterFees = pendingDeltaAfterFees;
    }

    position.pnl =
      (Number(position.pendingDelta) / 1e30) * (position.hasProfit ? 1 : -1);
    position.pnlAfterFee =
      (Number(position.pendingDeltaAfterFees) / 1e30) *
      (position.hasProfitAfterFees ? 1 : -1);
    position.netValue =
      position.collateral / 1e30 + position.pnl - position.fundingFee / 1e30;
    return position;
  };
  const userPositions = {};
  const parsedPositions = await Promise.all(
    allPositions.map((dataItem) => {
      return getPosition(dataItem);
    })
  );
  for (let i = 0; i < allPositions.length; i++) {
    const dataItem = allPositions[i];
    const { account } = dataItem;
    if (!userPositions[account]) {
      userPositions[account] = {
        account: account,
        positions: [],
        pnl: 0,
        profit: 0,
        loss: 0,
        pnlAfterFee: 0,
        profitAfterFee: 0,
        lossAfterFee: 0,
        netValue: 0,
      };
    }
    userPositions[account].positions.push(parsedPositions[i]);
    userPositions[account].pnl += parsedPositions[i].pnl;
    if (parsedPositions[i].pnl > 0) {
      userPositions[account].profit += parsedPositions[i].pnl;
    } else {
      userPositions[account].loss += parsedPositions[i].pnl;
    }
    userPositions[account].pnlAfterFee += parsedPositions[i].pnlAfterFee;
    if (parsedPositions[i].pnlAfterFee > 0) {
      userPositions[account].profitAfterFee += parsedPositions[i].pnlAfterFee;
    } else {
      userPositions[account].lossAfterFee += parsedPositions[i].pnlAfterFee;
    }
    userPositions[account].netValue += parsedPositions[i].netValue;
  }

  const users = Object.keys(userPositions);
  const [ensNames, pnsNames] = await Promise.all([
    getEnsNames(users),
    getPnsNames(users),
  ]);
  for (let i = 0; i < users.length; i++) {
    userPositions[users[i]].ens = ensNames[i];
    userPositions[users[i]].pns = pnsNames[i];
    userPositions[users[i]].xns = combineXnsName(ensNames[i], pnsNames[i]);
  }

  return userPositions;
}

export function useUserPositions({ chainName = "pulse" } = {}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [chainName]);

  useEffect(() => {
    getUserPositions(chainName).then((positions) => {
      setData(positions);
      setLoading(false);
    });
  }, [chainName, setLoading, setData]);
  return [data, loading];
}

const getEnsNames = async (allUsers) => {
  const ensNames = [];
  return ensNames;
  const count = 100;
  for (let i = 0; i < allUsers.length; i += count) {
    const users = allUsers.slice(i, i + count);
    const { data } = await ensSubgraphClient.query({
      query: gql(`
      {
        domains (
          where: {
            resolvedAddress_in: [${users
              .map((user) => `"${user.toLowerCase()}"`)
              .join(", ")}]
          }
        ) {
          name
          resolvedAddress {
            id
          }
        }
      }
    `),
    });
    const domains = data.domains;
    for (let j = 0; j < users.length; j++) {
      ensNames[i + j] = domains.find(
        (item) =>
          item.resolvedAddress.id.toLowerCase() === users[j].toLowerCase()
      )?.name;
    }
  }

  return ensNames;
};

const getPnsNames = async (allUsers) => {
  const ensNames = [];
  const count = 100;
  for (let i = 0; i < allUsers.length; i += count) {
    const users = allUsers.slice(i, i + count);
    const { data } = await pnsSubgraphClient.query({
      query: gql(`
      {
        domains (
          where: {
            resolvedAddress_in: [${users
              .map((user) => `"${user.toLowerCase()}"`)
              .join(", ")}]
          }
        ) {
          name
          resolvedAddress {
            id
          }
        }
      }
    `),
    });
    const domains = data.domains;
    for (let j = 0; j < users.length; j++) {
      ensNames[i + j] = domains.find(
        (item) =>
          item.resolvedAddress.id.toLowerCase() === users[j].toLowerCase()
      )?.name;
    }
  }

  return ensNames;
};

export async function getDecreasePositions(from = FIRST_DATE_TS, to = NOW_TS) {
  const res = [];
  const count = 1000;

  let skip = 0;
  // eslint-disable-next-line no-constant-condition
  while (1) {
    const positionClient = getPositionsClient(PULSE);
    const { data } = await positionClient.query({
      query: gql(`
      {
        decreasePositions (
          first: ${count}
          skip: ${skip}
          where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
        ) {
          account
          collateralDelta
          fee
          key
          sizeDelta
          timestamp
        }
      }
    `),
    });

    const positions = data.decreasePositions;

    res.push(...positions);

    if (positions.length < count) {
      break;
    }
    skip += count;
  }

  return res.map((item) => ({
    ...item,
    fee: Number(item.fee) / 1e30,
    sizeDelta: Number(item.sizeDelta) / 1e30,
    collateralDelta: Number(item.collateralDelta) / 1e30,
  }));
}

export function useAllDecreasePositions({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [from, to, chainName]);

  useEffect(() => {
    getDecreasePositions(from, to).then((positions) => {
      setData(positions);
      setLoading(false);
    });
  }, [from, to, chainName, setLoading, setData]);
  return [data, loading];
}

export async function getIncreasePositions(from = FIRST_DATE_TS, to = NOW_TS) {
  const res = [];
  const count = 1000;

  let skip = 0;
  // eslint-disable-next-line no-constant-condition
  while (1) {
    const positionClient = getPositionsClient(PULSE);
    const { data } = await positionClient.query({
      query: gql(`
      {
        increasePositions (
          first: ${count}
          skip: ${skip}
          where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
        ) {
          account
          collateralDelta
          fee
          key
          sizeDelta
          timestamp
        }
      }
    `),
    });

    const positions = data.increasePositions;

    res.push(...positions);

    if (positions.length < count) {
      break;
    }
    skip += count;
  }

  return res.map((item) => ({
    ...item,
    fee: Number(item.fee) / 1e30,
    sizeDelta: Number(item.sizeDelta) / 1e30,
    collateralDelta: Number(item.collateralDelta) / 1e30,
  }));
}

export function useAllIncreasePositions({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [from, to, chainName]);

  useEffect(() => {
    getIncreasePositions(from, to).then((positions) => {
      setData(positions);
      setLoading(false);
    });
  }, [from, to, chainName, setLoading, setData]);
  return [data, loading];
}

export function useAllCreateIncreasePositions({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [from, to, chainName]);

  useEffect(() => {
    getCreateIncreasePositions(from, to).then((positions) => {
      setData(positions);
      setLoading(false);
    });
  }, [from, to, chainName, setLoading, setData]);
  return [data, loading];
}

export async function getClosePositions(from = FIRST_DATE_TS, to = NOW_TS) {
  const res = [];
  const count = 1000;

  let skip = 0;
  // eslint-disable-next-line no-constant-condition
  while (1) {
    const positionClient = getPositionsClient(PULSE);
    const { data } = await positionClient.query({
      query: gql(`
      {
        closePositions (
          first: ${count}
          skip: ${skip}
          where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
        ) {
          realisedPnl
          key
          timestamp
          size
        }
      }
    `),
    });

    const positions = data.closePositions;

    res.push(...positions);

    if (positions.length < count) {
      break;
    }
    skip += count;
  }

  return res.map((item) => ({
    ...item,
    realisedPnl: Number(item.realisedPnl) / 1e30,
    size: Number(item.size) / 1e30,
  }));
}

export function useAllClosePositions({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [from, to, chainName]);

  useEffect(() => {
    getClosePositions(from, to).then((positions) => {
      setData(positions);
      setLoading(false);
    });
  }, [from, to, chainName, setLoading, setData]);
  return [data, loading];
}

export async function getLiquidatedPositions(
  from = FIRST_DATE_TS,
  to = NOW_TS
) {
  const res = [];
  const count = 1000;

  let skip = 0;
  // eslint-disable-next-line no-constant-condition
  while (1) {
    const positionClient = getStatsClient(PULSE);
    const { data } = await positionClient.query({
      query: gql(`
      {
        liquidatedPositions(
          first: ${count}
          skip: ${skip}
          where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
        ) {
          account
          collateral
          borrowFee
          key
          timestamp
          loss
          size
          type
        }
      }
    `),
    });

    const positions = data.liquidatedPositions;

    res.push(...positions);

    if (positions.length < count) {
      break;
    }
    skip += count;
  }

  return res.map((item) => ({
    ...item,
    loss: Number(item.loss) / 1e30,
    collateral: Number(item.collateral) / 1e30,
    borrowFee: Number(item.borrowFee) / 1e30,
    size: Number(item.size) / 1e30,
  }));
}

export function useAllLiquidatedPositions({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [from, to, chainName]);

  useEffect(() => {
    getLiquidatedPositions(from, to).then((positions) => {
      setData(positions);
      setLoading(false);
    });
  }, [from, to, chainName, setLoading, setData]);
  return [data, loading];
}

export async function getUpdatePositions(from = FIRST_DATE_TS, to = NOW_TS) {
  const res = [];
  const count = 1000;

  let skip = 0;
  // eslint-disable-next-line no-constant-condition
  while (1) {
    const positionClient = getPositionsClient(PULSE);
    const { data } = await positionClient.query({
      query: gql(`
      {
        updatePositions (
          first: ${count}
          skip: ${skip}
          where: { timestamp_gte: ${from}, timestamp_lte: ${to} }
        ) {
          realisedPnl
          markPrice
          size
          key
          timestamp
        }
      }
    `),
    });

    const positions = data.updatePositions;

    res.push(...positions);

    if (positions.length < count) {
      break;
    }
    skip += count;
  }

  return res.map((item) => ({
    ...item,
    markPrice: Number(item.markPrice) / 1e30,
    realisedPnl: Number(item.realisedPnl) / 1e30,
    size: Number(item.size) / 1e30,
  }));
}

export function useAllUpdatePositions({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "pulse",
} = {}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [from, to, chainName]);

  useEffect(() => {
    getUpdatePositions(from, to).then((positions) => {
      setData(positions);
      setLoading(false);
    });
  }, [from, to, chainName, setLoading, setData]);
  return [data, loading];
}

export async function getUserActivities(
  users,
  from = FIRST_DATE_TS,
  to = NOW_TS
) {
  const res = [];
  const count = 1000;

  let skip = 0;
  // eslint-disable-next-line no-constant-condition
  while (1) {
    const client = getStatsClient(PULSE);
    const { data } = await client.query({
      query: gql(`
      {
        userActions (
          first: ${count}
          skip: ${skip}
          where: { 
            account_in: [${users
              .map((item) => '"' + item.toLowerCase() + '"')
              .join(" ")}], 
            timestamp_gte: ${from}, 
            timestamp_lte: ${to} 
          }
          orderBy: timestamp 
          orderDirection: desc
        ) {
          account
          action
          actionType
          timestamp
        }
      }
    `),
    });

    const userActions = data.userActions;

    res.push(...userActions);

    if (userActions.length < count) {
      break;
    }
    skip += count;
  }

  return res.map((item) => {
    return {
      action: item.actionType,
      params: item.action,
      timestamp: item.timestamp,
      txhash: JSON.parse(item.action).txhash,
      account: item.account,
    };
  });
}

export function useAllUserActivities({
  users,
  chainName = "pulse",
  from = FIRST_DATE_TS,
  to = NOW_TS,
} = {}) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [users, chainName]);

  useEffect(() => {
    if (users[0]) {
      getUserActivities(users, from, to).then((activities) => {
        setData(activities);
        setLoading(false);
      });
    }
  }, [users, setData, setLoading]);
  return [data, loading];
}
