import React, { useState, useEffect } from "react";
import {
  RiCheckboxBlankFill,
  RiCheckboxFill,
  RiLoader5Fill,
} from "react-icons/ri";
import Pagination from "../Pagination/Pagination";
import XnsTooltip from "./XnsTooltip";
import CopyToClipboard from "./CopyToClipboard";

const formatPNL = (amount) => {
  if (amount < 0) {
    return `- $${Number((-amount).toFixed(0)).toLocaleString("en-US")}`;
  }
  return `$${Number(amount.toFixed(0)).toLocaleString("en-US")}`;
};

function TopTraders({ traders, allTradersLoading }) {
  const [tradersLimit, setTradersLimit] = useState(50);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 1024;

  const [pnlSortOption, setPnlSortOption] = useState({
    key: "volume",
    asc: false,
  });

  const pageSize = 10;
  const [page, setPage] = useState(1);

  const [pnlOption, setPnlOption] = useState(1);

  return (
    <div
      className="chart-cell"
      style={{
        height: "fit-content",
        gridColumnStart: isMobile ? "span 10" : "span 5",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Top Traders By Volume</h3>
        {/* {!allTradersLoading && traders && traders.length > 0 && (
          <div
            onClick={() => {
              setPnlOption(3 - pnlOption);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {pnlOption === 1 ? (
              <RiCheckboxBlankFill
                size="1.5em"
                style={{ marginRight: "10px" }}
              />
            ) : (
              <RiCheckboxFill size="1.5em" style={{ marginRight: "10px" }} />
            )}
            Show PnL after fees
          </div>
        )} */}
      </div>
      {allTradersLoading && <RiLoader5Fill size="3em" className="loader" />}
      {!allTradersLoading && (
        <table className="traders-table">
          <thead>
            <th>No.</th>
            <th>Address</th>
            <th
              style={{
                cursor: "pointer",
                textDecoration: pnlSortOption.key === "volume" && "underline",
              }}
              onClick={() => {
                setPnlSortOption({
                  key: "volume",
                  asc:
                    pnlSortOption.key === "volume" ? !pnlSortOption.asc : false,
                });
              }}
            >
              Total Volume (USD)
            </th>
            <th
              style={{
                cursor: "pointer",
                textDecoration: pnlSortOption.key === "pnl" && "underline",
              }}
              onClick={() => {
                setPnlSortOption({
                  key: "pnl",
                  asc: pnlSortOption.key === "pnl" ? !pnlSortOption.asc : false,
                });
              }}
            >
              All time PnL
            </th>
          </thead>
          <tbody>
            {traders
              .sort((a, b) => {
                if (pnlOption === 1) {
                  if (pnlSortOption.key === "pnl") {
                    return pnlSortOption.asc
                      ? a.earnedPnL - b.earnedPnL
                      : b.earnedPnL - a.earnedPnL;
                  }
                } else {
                  if (pnlSortOption.key === "pnl") {
                    return pnlSortOption.asc
                      ? a.earnedPnLAfterFee - b.earnedPnLAfterFee
                      : b.earnedPnLAfterFee - a.earnedPnLAfterFee;
                  }
                }
                if (pnlSortOption.key === "volume") {
                  return pnlSortOption.asc
                    ? a.amount - b.amount
                    : b.amount - a.amount;
                }
                return b.amount - a.amount;
              })
              .slice(0, tradersLimit)
              // .slice(pageSize * (page - 1), pageSize * page)
              .map((item, index) => {
                return (
                  <tr key={item.account}>
                    <td>{index + 1}</td>
                    {/* <td>{pageSize * (page - 1) + index + 1}</td> */}
                    <td>
                      <a
                        href={`https://phatty.io/profile/${item.account}/portfolio`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        <XnsTooltip
                          account={item.account}
                          xns={item.xns}
                          ens={item.ens}
                          pns={item.pns}
                        ></XnsTooltip>
                      </a>
                      <CopyToClipboard text={item.account} />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatPNL(item.amount)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {pnlOption === 1
                        ? formatPNL(item.earnedPnL)
                        : formatPNL(item.earnedPnLAfterFee)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {traders.length > tradersLimit && (
        <div
          style={{
            marginTop: "10px",
            textAlign: "center",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => setTradersLimit(tradersLimit + 50)}
        >
          Load More
        </div>
      )}
      {/* <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={traders.length}
        pageSize={pageSize}
        onPageChange={(page) => setPage(page)}
      /> */}
      <div style={{ textAlign: "right" }}>(Calculations are before fees)</div>
    </div>
  );
}

export default TopTraders;
