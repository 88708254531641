import React, { useMemo, useState, useEffect } from "react";
import { RiLoader5Fill } from "react-icons/ri";

const index_tokens = [
  "0xa1077a294dde1b09bb078844df40758a5d0f9a27",
  "0x95b303987a60c71504d99aa1b13b4da07b0790ab",
  "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39",
  "0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c",
  "0xb17d901469b9208b17d916112988a3fed19b5ca1",
];
const address_to_symbol = {
  "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07": "USDC",
  "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f": "USDT",
  "0xefd766ccb38eaf1dfd701853bfce31359239f305": "DAI",
  "0x95b303987a60c71504d99aa1b13b4da07b0790ab": "PLSX",
  "0xa1077a294dde1b09bb078844df40758a5d0f9a27": "PLS",
  "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39": "HEX",
  "0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c": "WETH",
  "0xb17d901469b9208b17d916112988a3fed19b5ca1": "WBTC",
};
const symbol_to_icon = {
  USDC: "https://tokens.app.pulsex.com/images/tokens/0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07.png",
  USDT: "https://tokens.app.pulsex.com/images/tokens/0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f.png",
  DAI: "https://tokens.app.pulsex.com/images/tokens/0xefD766cCb38EaF1dfd701853BFCe31359239F305.png",
  PLSX: "https://phatty.io/img/tokens/pulsex.jpeg",
  PLS: "https://phatty.io/img/tokens/wpls.webp",
  HEX: "https://tokens.app.pulsex.com/images/tokens/0x2b591e99afE9f32eAA6214f7B7629768c40Eeb39.png",
  WETH: "https://tokens.app.pulsex.com/images/tokens/0x02DcdD04e3F455D838cd1249292C58f3B79e3C3C.png",
  WBTC: "https://tokens.app.pulsex.com/images/tokens/0xb17D901469B9208B17d916112988A3FeD19b5cA1.png",
};
const symbol_to_limit = {
  PLS: {
    long: 800000,
    short: 200000
  },
  PLSX: {
    long: 400000,
    short: 100000
  },
  HEX: {
    long: 400000,
    short: 100000
  },
  WETH: {
    long: 200000,
    short: 100000
  },
  WBTC: {
    long: 200000,
    short: 100000
  },
};

function OpenInterest({ userPositions }) {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  const data = useMemo(() => {
    if (!userPositions) {
      return [];
    }
    console.log("userPositions = ", userPositions);
    const res = [];
    for (let i = 0; i < index_tokens.length; i++) {
      const indexToken = index_tokens[i];
      let long_size = 0;
      let short_size = 0;
      let long_collateral = 0;
      let short_collateral = 0;

      for (let j = 0; j < Object.values(userPositions).length; j++) {
        const userPosition = Object.values(userPositions)[j];
        for (let k = 0; k < userPosition.positions.length; k++) {
          const position = userPosition.positions[k];
          if (position.indexToken.toLowerCase() === indexToken.toLowerCase()) {
            if (position.isLong) {
              long_size += Number(position.size) / 1e30;
              long_collateral += Number(position.collateral) / 1e30;
            } else {
              short_size += Number(position.size) / 1e30;
              short_collateral += Number(position.collateral) / 1e30;
            }
          }
        }
      }
      const total_size = long_size + short_size ?? 1;
      res.push({
        symbol: address_to_symbol[indexToken],
        long_percent: (long_size * 100) / total_size,
        short_percent: (short_size * 100) / total_size,
        long_size: long_size,
        short_size: short_size,
        long_collateral: long_collateral,
        short_collateral: short_collateral,
        long_leverage: long_size / long_collateral,
        short_leverage: short_size / short_collateral,
      });
    }
    return res;
  }, [userPositions]);
  return (
    <div
      className="chart-cell"
      style={{ height: "fit-content", gridColumnStart: "span 10" }}
    >
      <h3>Open Interest Snapshot</h3>
      {(!data || data.length == 0) && (
        <RiLoader5Fill size="3em" className="loader" />
      )}
      {data?.length > 0 && (
        <table className="traders-table">
          <thead>
            <th
              style={{
                textAlign: "left",
                paddingRight: "60px",
                width: "60px",
              }}
            >
              Token
            </th>
            {isMobile && (
              <>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "40px",
                    width: "50px",
                  }}
                >
                  Long Size
                  <br />
                  (Percent)
                </th>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "40px",
                    width: "50px",
                  }}
                >
                  Short Size
                  <br />
                  (Percent)
                </th>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "40px",
                    width: "50px",
                  }}
                >
                  Long Collateral
                  <br />
                  (Average Leverage)
                </th>
                <th style={{ textAlign: "left", width: "50px" }}>
                  Short Collateral
                  <br />
                  (Average Leverage)
                </th>
              </>
            )}
            {!isMobile && (
              <>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "20px",
                    width: "60px",
                  }}
                >
                  Long Size
                </th>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "20px",
                    width: "60px",
                  }}
                >
                  Short Size
                </th>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "20px",
                    width: "60px",
                  }}
                >
                  Long Percent
                </th>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "20px",
                    width: "60px",
                  }}
                >
                  Short Percent
                </th>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "20px",
                    width: "60px",
                  }}
                >
                  Long Collateral
                </th>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "20px",
                    width: "60px",
                  }}
                >
                  Short Collateral
                </th>
                <th
                  style={{
                    textAlign: "left",
                    paddingRight: "20px",
                    width: "60px",
                  }}
                >
                  Long Average Leverage
                </th>
                <th style={{ textAlign: "left", width: "60px" }}>
                  Short Average Leverage
                </th>
              </>
            )}
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr key={item.symbol}>
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      height="30"
                      src={symbol_to_icon[item.symbol]}
                      style={{ marginRight: "10px", borderRadius: "100%" }}
                    />
                    {item.symbol}
                  </td>
                  {isMobile && (
                    <>
                      <td>
                        $
                        {Number(item.long_size.toFixed(2)).toLocaleString(
                          "en-US"
                        )}
                        <br />({item.long_percent.toFixed(2)}%)
                      </td>
                      <td>
                        $
                        {Number(item.short_size.toFixed(2)).toLocaleString(
                          "en-US"
                        )}
                        <br />({item.short_percent.toFixed(2)}%)
                      </td>
                      <td>
                        $
                        {Number(item.long_collateral.toFixed(2)).toLocaleString(
                          "en-US"
                        )}
                        <br />({item.long_leverage.toFixed(2)}x)
                      </td>
                      <td>
                        $
                        {Number(
                          item.short_collateral.toFixed(2)
                        ).toLocaleString("en-US")}
                        <br />({item.short_leverage.toFixed(2)}x)
                      </td>
                    </>
                  )}
                  {!isMobile && (
                    <>
                      <td>
                        $
                        {Number(item.long_size.toFixed(2)).toLocaleString(
                          "en-US"
                        )}
                      </td>
                      <td>
                        $
                        {Number(item.short_size.toFixed(2)).toLocaleString(
                          "en-US"
                        )}
                      </td>
                      <td>{item.long_percent.toFixed(2)}%</td>
                      <td>{item.short_percent.toFixed(2)}%</td>
                      <td>
                        $
                        {Number(item.long_collateral.toFixed(2)).toLocaleString(
                          "en-US"
                        )}
                      </td>
                      <td>
                        $
                        {Number(
                          item.short_collateral.toFixed(2)
                        ).toLocaleString("en-US")}
                      </td>
                      <td>{item.long_leverage.toFixed(2)}x</td>
                      <td>{item.short_leverage.toFixed(2)}x</td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div style={{ textAlign: "right" }}>Data might be delayed or inaccurate. for reference only</div>
    </div>
  );
}

export default OpenInterest;
