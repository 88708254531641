import React, { useEffect, useState } from "react";
import { RiFileCopyLine, RiCheckLine } from "react-icons/ri";

function CopyToClipboard({ text }) {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setCopied(false);
  }, [text]);

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return !copied ? (
    <RiFileCopyLine
      size="1em"
      style={{ marginLeft: "2px", cursor: "pointer" }}
      onClick={handleCopy}
    />
  ) : (
    <RiCheckLine size="1em" style={{ marginLeft: "2px" }} />
  );
}

export default CopyToClipboard;
