import React, { useMemo, useCallback, useState, useEffect, useRef } from "react";
import { RiLoader5Fill } from "react-icons/ri";
import Tooltip from 'rc-tooltip';
import { CSVLink } from 'react-csv';
import { gql } from "@apollo/client";
import { PULSE } from "../../addresses";
import { useAllUserActivities } from "../../dataProvider";
import { ethers } from 'ethers';
import { getStatsClient } from "../../graph";
import { format as formatDateFn } from 'date-fns';
import { formatAddress } from "../../utils";
import '../ToolTip/index.css'

const formatPNL = (amount, decimals = 0) => {
  if (amount < 0) {
    return `- $${Number((-amount).toFixed(decimals)).toLocaleString("en-US")}`;
  }
  return `$${Number(amount.toFixed(decimals)).toLocaleString("en-US")}`;
};

const index_tokens = [
  "0xa1077a294dde1b09bb078844df40758a5d0f9a27",
  "0x95b303987a60c71504d99aa1b13b4da07b0790ab",
  "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39",
  "0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c",
  "0xb17d901469b9208b17d916112988a3fed19b5ca1",
];
const address_to_symbol = {
  "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07": "USDC",
  "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f": "USDT",
  "0xefd766ccb38eaf1dfd701853bfce31359239f305": "DAI",
  "0x95b303987a60c71504d99aa1b13b4da07b0790ab": "PLSX",
  "0xa1077a294dde1b09bb078844df40758a5d0f9a27": "PLS",
  "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39": "HEX",
  "0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c": "WETH",
  "0xb17d901469b9208b17d916112988a3fed19b5ca1": "WBTC",
};

export const USD_DECIMALS = 30;
export const USDPH_DECIMALS = 18;

export function bigNumberify(n) {
  return ethers.BigNumber.from(n);
}

export function deserialize(data) {
  for (const [key, value] of Object.entries(data)) {
    if (value._type === 'BigNumber') {
      data[parseInt(key)] = bigNumberify(value.value);
    }
  }
  return data;
}

export const limitDecimals = (amount, maxDecimals) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split('.')[0];
  }
  const dotIndex = amountStr.indexOf('.');
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};

export const padDecimals = (amount, minDecimals) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf('.');
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), '0');
    }
  } else {
    amountStr = amountStr + '.0000';
  }
  return amountStr;
};

export function numberWithCommas(x) {
  if (!x) {
    return '...';
  }
  const parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const formatAmount = (
  amount,
  tokenDecimals,
  displayDecimals,
  useCommas,
  defaultValue,
  smartDecimals,
) => {
  if (!defaultValue) {
    defaultValue = '...';
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  if (smartDecimals === undefined) {
    smartDecimals = 3;
  }
  const originalDisplayDecimals = displayDecimals;
  const baseStr = ethers.utils.formatUnits(amount, tokenDecimals);
  let amountStr;
  let needSmart = false;
  while (true) {
    amountStr = limitDecimals(baseStr, displayDecimals);
    if (displayDecimals !== 0) {
      amountStr = padDecimals(amountStr, displayDecimals);
    }
    if (
      (typeof amount === 'number' && amount === 0) ||
      (amount instanceof ethers.BigNumber && amount.eq(0)) ||
      !smartDecimals ||
      (displayDecimals >= 10 && displayDecimals >= 2 * originalDisplayDecimals)
    ) {
      break;
    } else if (
      !needSmart &&
      (Number(amountStr) === 0 || amountStr.length < smartDecimals || Number(amountStr.slice(0, -smartDecimals)) === 0)
    ) {
      needSmart = true;
      displayDecimals++;
    } else if (
      needSmart &&
      (amountStr.length <= smartDecimals || amountStr[amountStr.length - smartDecimals] === '0')
    ) {
      displayDecimals++;
    } else {
      break;
    }
  }
  if (Number(amountStr) === 0) {
    amountStr = limitDecimals(baseStr, originalDisplayDecimals);
    if (displayDecimals !== 0) {
      amountStr = padDecimals(amountStr, originalDisplayDecimals);
    }
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

function getPositionDisplay(
  increase,
  indexToken,
  isLong,
  sizeDelta,
) {
  const symbol = indexToken ? (indexToken.isWrapped ? indexToken.baseSymbol : indexToken.symbol) : '';
  return `
    ${increase ? 'Increase' : 'Decrease'} ${symbol} ${isLong ? 'Long' : 'Short'}
    ${increase ? '+' : '-'}${formatAmount(sizeDelta, USD_DECIMALS, 2, true, undefined, 0)} USD`;
}

const MAX_LEVERAGE = 50;
function renderLiquidationTooltip(
  liquidationData,
) {
  const minCollateral = liquidationData.size / MAX_LEVERAGE;
  const loss = liquidationData.loss;
  const text =
    parseFloat(loss) < 0
      ? 'Your collateral becomes too low to cover all the remaining fees, including borrow fees & potential close fees to fully close the position'
      : 'Liquidation price is reached';
  return (
    <div>
      {text}
      <br />
      <br />
      Initial collateral: ${numberWithCommas(limitDecimals(liquidationData.collateral, 2))}
      <br />
      Min required collateral: ${numberWithCommas(limitDecimals(minCollateral, 2))}
      <br />
      Borrow fee: ${numberWithCommas(limitDecimals(liquidationData.borrowFee, 2))}
      {liquidationData.type === 'full' && (
        <div>Liquidation fee: ${numberWithCommas(limitDecimals(5, 2))}</div>
      )}
    </div>
  );
}

function getOrderActionTitle(action) {
  let actionDisplay;

  if (action.startsWith('Create')) {
    actionDisplay = 'Create';
  } else if (action.startsWith('Cancel')) {
    actionDisplay = 'Cancel';
  } else {
    actionDisplay = 'Update';
  }

  return `${actionDisplay} Order`;
}

export function formatDateTime(time) {
  return formatDateFn(time * 1000, 'dd MMM yyyy, h:mm a');
}

export function shouldInvertTriggerRatio(tokenA, tokenB) {
  if (tokenB.isStable || tokenB.isUsdph) return true;
  if (tokenB.maxPrice && tokenA.maxPrice && tokenB.maxPrice.lt(tokenA.maxPrice)) return true;
  return false;
}

export function getExchangeRateDisplay(rate, tokenA, tokenB) {
  if (!rate || !tokenA || !tokenB) return '...';
  if (shouldInvertTriggerRatio(tokenA, tokenB)) {
    [tokenA, tokenB] = [tokenB, tokenA];
    rate = PRECISION.mul(PRECISION).div(rate);
  }
  const rateValue = formatAmount(rate, USD_DECIMALS, tokenA.isStable || tokenA.isUsdph ? 2 : 4, true);
  if (opts?.omitSymbols) {
    return rateValue;
  }
  return `${rateValue} ${tokenA.symbol} / ${tokenB.symbol}`;
}

const getTokenInfo = (token) => {
  if (token.toLowerCase() === '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07'.toLowerCase()) {
    return {
      symbol: "USDC",
      decimals: 6,
      isStable: true,
    } 
  }
  if (token.toLowerCase() === '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f'.toLowerCase()) {
    return {
      symbol: "USDT",
      decimals: 6,
      isStable: true,
    } 
  }
  if (token.toLowerCase() === '0xefd766ccb38eaf1dfd701853bfce31359239f305'.toLowerCase()) {
    return {
      symbol: "DAI",
      decimals: 18,
      isStable: true,
    } 
  }
  if (token.toLowerCase() === '0x95b303987a60c71504d99aa1b13b4da07b0790ab'.toLowerCase()) {
    return {
      symbol: "PLSX",
      decimals: 18,
      isStable: true,
    } 
  }
  if (token.toLowerCase() === '0xa1077a294dde1b09bb078844df40758a5d0f9a27'.toLowerCase()) {
    return {
      symbol: "PLS",
      decimals: 18,
      isStable: false,
    } 
  }
  if (token.toLowerCase() === '0x2b591e99afe9f32eaa6214f7b7629768c40eeb39'.toLowerCase()) {
    return {
      symbol: "HEX",
      decimals: 8,
      isStable: true,
    } 
  }
  if (token.toLowerCase() === '0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c'.toLowerCase()) {
    return {
      symbol: "WETH",
      decimals: 18,
      isStable: true,
    } 
  }
  if (token.toLowerCase() === '0xb17d901469b9208b17d916112988a3fed19b5ca1'.toLowerCase()) {
    return {
      symbol: "WBTC",
      decimals: 8,
      isStable: true,
    } 
  }
}

function findTradePositionByDetail(positions, trade, tradeParams){
  let { sizeDelta, size, key } = tradeParams
   let results = positions?.filter(pos => Number(pos.timestamp) === Number(trade.timestamp) && pos.key === key && (pos.action === 'ClosePosition' || pos.__typename === "LiquidatedPosition"));
   if(results.length <= 1){
    return results[0]
   }

   if(trade.action === "LiquidatedPosition"){
     size = Number(size) / 1e30
     results = results.filter(pos => pos.size === size)
   }else if(trade.action === "DecreasePosition"){
    sizeDelta = Number(sizeDelta) / 1e30
    results = results.filter(pos => pos.sizeDelta === sizeDelta)
   }
   return results[0]
}

function UserActivity({ users, userPnlOverTime, userPositions, params}) {
  const [trades, loading] = useAllUserActivities({ users, ...params });

  const getMsg = useCallback(
    (trade: { data: any }, tradeParams: Record<string, any>) => {
      const tradeData = trade;
      const params = tradeParams
      if(params.isLong) {
        params.isLong = typeof params.isLong === 'boolean' ? params.isLong : params.isLong === 'true';
      }
      const defaultMsg = '';

      if (tradeData.action === 'BuyUSDPH') {
        const token = getTokenInfo(params.token);
        if (!token) {
          return defaultMsg;
        }
        return `Swap ${formatAmount(params.tokenAmount, token.decimals, 4, true, undefined, 0)} ${
          token.symbol
        } for ${formatAmount(params.usdphAmount, USDPH_DECIMALS, 4, true, undefined, 0)} USDPH`;
      }

      if (tradeData.action === 'SellUSDPH') {
        const token = getTokenInfo(params.token);
        if (!token) {
          return defaultMsg;
        }
        return `Swap ${formatAmount(
          params.usdphAmount,
          USDPH_DECIMALS,
          4,
          true,
          undefined,
          0,
        )} USDPH for ${formatAmount(params.tokenAmount, token.decimals, 4, true, undefined, 0)} ${token.symbol}`;
      }

      if (tradeData.action === 'Swap') {
        const tokenIn = getTokenInfo(params.tokenIn);
        const tokenOut = getTokenInfo(params.tokenOut);
        if (!tokenIn || !tokenOut) {
          return defaultMsg;
        }
        return `Swap ${formatAmount(params.amountIn, tokenIn.decimals, 4, true, undefined, 0)} ${
          tokenIn.symbol
        } for ${formatAmount(params.amountOut, tokenOut.decimals, 4, true, undefined, 0)} ${tokenOut.symbol}`;
      }

      if (tradeData.action === 'CreateIncreasePosition') {
        const indexToken = getTokenInfo(params.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Request deposit into ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'}`;
        }

        return `Request increase ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'}, +${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          4,
          true,
          undefined,
          0,
        )} USD, Acceptable Price: ${params.isLong ? '<' : '>'} ${formatAmount(
          params.acceptablePrice,
          USD_DECIMALS,
          4,
          true,
          undefined,
          0,
        )} USD`;
      }

      if (tradeData.action === 'CreateDecreasePosition') {
        const indexToken = getTokenInfo(params.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Request withdrawal from ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'}`;
        }

        return `Request decrease ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'}, -${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          4,
          true,
          undefined,
          0,
        )} USD, Acceptable Price: ${params.isLong ? '>' : '<'} ${formatAmount(
          params.acceptablePrice,
          USD_DECIMALS,
          4,
          true,
        )} USD`;
      }

      if (tradeData.action === 'CancelIncreasePosition') {
        const indexToken = getTokenInfo(params.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Could not execute deposit into ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'}`;
        }

        return `Could not increase ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'},
          ${formatAmount(params.sizeDelta, USD_DECIMALS, 4, true, undefined, 0)} USD, Acceptable Price: 
          ${params.isLong ? '< ' : '> '}
          ${formatAmount(params.acceptablePrice, USD_DECIMALS, 4, true, undefined, 0)} USD`;
          {/* <Tooltip
            position="left-top"
            handle={``}
            renderContent={() => <>Try increasing the "Allowed Slippage", under the Settings menu on the top right</>}
          /> */}
      }

      if (tradeData.action === 'CancelDecreasePosition') {
        const indexToken = getTokenInfo(params.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }

        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Could not execute withdrawal from ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'}`;
        }

        return `Could not decrease ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'},
            ${formatAmount(params.sizeDelta, USD_DECIMALS, 4, true, undefined, 0)} USD, Acceptable Price: 
            ${params.isLong ? '> ' : '< '}
            ${formatAmount(params.acceptablePrice, USD_DECIMALS, 4, true)} USD`;
          {/* <Tooltip
            position="left-top"
            handle={`$`}
            renderContent={() => <>Try increasing the "Allowed Slippage", under the Settings menu on the top right</>}
          /> */}
      }

      if (tradeData.action === 'IncreasePosition') {
        if (params.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenInfo(params.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Deposit ${formatAmount(params.collateralDelta, USD_DECIMALS, 4, true, undefined, 0)} USD into ${
            indexToken.symbol
          } ${params.isLong ? 'Long' : 'Short'}`;
        }
        return `Increase ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'}, +${formatAmount(
          params.sizeDelta,
          USD_DECIMALS,
          4,
          true,
          undefined,
          0,
        )} USD, ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 4, true)} USD`;
      }

      if (tradeData.action === 'DecreasePosition') {
        if (params.flags?.isOrderExecution) {
          return;
        }

        const indexToken = getTokenInfo(params.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        if (bigNumberify(params.sizeDelta).eq(0)) {
          return `Withdraw ${formatAmount(params.collateralDelta, USD_DECIMALS, 4, true, undefined, 0)} USD from ${
            indexToken.symbol
          } ${params.isLong ? 'Long' : 'Short'}`;
        }
        const isLiquidation = params.flags?.isLiquidation;
        const actionDisplay = isLiquidation ? 'Partially Liquidated' : 'Decreased';
        return `
        ${actionDisplay} ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'},
        -${formatAmount(params.sizeDelta, USD_DECIMALS, 4, true, undefined, 0)} USD,
        ${indexToken.symbol} Price: ${formatAmount(params.price, USD_DECIMALS, 4, true)} USD
      `;
      }

      if (tradeData.action === 'LiquidatePosition') {
        const indexToken = getTokenInfo(params.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        return `
        Liquidated ${indexToken.symbol} ${params.isLong ? 'Long' : 'Short'},
        -${formatAmount(params.size, USD_DECIMALS, 4, true, undefined, 0)} USD,
        ${indexToken.symbol} Price: ${formatAmount(params.markPrice, USD_DECIMALS, 4, true)} USD
      `;
      }

      if (['ExecuteIncreaseOrder', 'ExecuteDecreaseOrder'].includes(tradeData.action)) {
        const order = deserialize(params) as any;
        const indexToken = getTokenInfo(order.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const longShortDisplay = order.isLong ? 'Long' : 'Short';
        const executionPriceDisplay = formatAmount(order.executionPrice, USD_DECIMALS, 4, true);
        const sizeDeltaDisplay = `${order.type === 'Increase' ? '+' : '-'}${formatAmount(
          order.sizeDelta,
          USD_DECIMALS,
          4,
          true,
          undefined,
          0,
        )}`;

        return `
        Execute Order: ${tradeData.action === 'ExecuteIncreaseOrder' ? 'Increase' : 'Decrease'} ${
          indexToken.symbol
        } ${longShortDisplay}
        ${sizeDeltaDisplay} USD, Price: ${executionPriceDisplay} USD
      `;
      }

      if (
        [
          'CreateIncreaseOrder',
          'CancelIncreaseOrder',
          'UpdateIncreaseOrder',
          'CreateDecreaseOrder',
          'CancelDecreaseOrder',
          'UpdateDecreaseOrder',
        ].includes(tradeData.action)
      ) {
        const order = deserialize(params) as any;
        const indexToken = getTokenInfo(order.indexToken);
        if (!indexToken) {
          return defaultMsg;
        }
        const increase = tradeData.action.includes('Increase');
        const priceDisplay = `${order.triggerAboveThreshold ? '>' : '<'} ${formatAmount(
          order.triggerPrice,
          USD_DECIMALS,
          4,
          true,
        )}`;
        return `
        ${getOrderActionTitle(tradeData.action)}:
        ${getPositionDisplay(increase, indexToken, order.isLong, order.sizeDelta)},
        Price: ${priceDisplay}
      `;
      }

      if (tradeData.action === 'ExecuteSwapOrder') {
        const order = deserialize(params) as any;
        const fromToken = getTokenInfo(order.path[0] === '0xa1077a294dde1b09bb078844df40758a5d0f9a27' ? AddressZero : order.path[0]);
        const toToken = getTokenInfo(order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const fromAmountDisplay = formatAmount(
          order.amountIn,
          fromToken.decimals,
          fromToken.isStable ? 2 : 4,
          true,
          undefined,
          0,
        );
        const toAmountDisplay = formatAmount(
          order.amountOut,
          toToken.decimals,
          toToken.isStable ? 2 : 4,
          true,
          undefined,
          0,
        );
        return `
        Execute Order: Swap ${fromAmountDisplay} ${fromToken.symbol} for ${toAmountDisplay} ${toToken.symbol}
      `;
      }

      if (['CreateSwapOrder', 'UpdateSwapOrder', 'CancelSwapOrder'].includes(tradeData.action)) {
        const order = deserialize(params) as any;
        const fromToken = getTokenInfo(order.path[0] === '0xa1077a294dde1b09bb078844df40758a5d0f9a27' ? AddressZero : order.path[0]);
        const toToken = getTokenInfo(order.shouldUnwrap ? AddressZero : order.path[order.path.length - 1]);
        if (!fromToken || !toToken) {
          return defaultMsg;
        }
        const amountInDisplay = fromToken
          ? formatAmount(order.amountIn, fromToken.decimals, fromToken.isStable ? 2 : 4, true, undefined, 0)
          : '';
        const minOutDisplay = toToken
          ? formatAmount(order.minOut, toToken.decimals, toToken.isStable ? 2 : 4, true, undefined, 0)
          : '';

        return `
        ${getOrderActionTitle(tradeData.action)}:
        Swap ${amountInDisplay} ${fromToken?.symbol || ''} for ${minOutDisplay} ${toToken?.symbol || ''},
        Price: ${getExchangeRateDisplay(order.triggerRatio, fromToken, toToken)}`;
      }
    },
    [getTokenInfo],
  );

  const tradesWithMessages = useMemo(() => {
    const userTradeHistory = userPnlOverTime.find(item => 
      {
        for (let i = 0; i < users.length; i++) {
          if (item.account.toLowerCase() === users[i].toLowerCase())
          {
            return true;
          }
        }
        return false;
      });

    if (!trades) {
      return [];
    }

    return trades
      .map((trade) => {
        const {params: originParams, action} = trade
        const tradeParams = JSON.parse(originParams);
        let msg = getMsg(trade, tradeParams);
        const originMsg = msg;
        if(action === "LiquidatePosition" || action === "DecreasePosition"){
          const tradePosition = userTradeHistory?.positions && findTradePositionByDetail(userTradeHistory?.positions, trade, tradeParams);
          if (tradePosition && typeof tradePosition.realisedPnl === "number") {
            let tipMsg = 'All profits from decrease position will be calculated uniformly when closing the position'
            if(action === "LiquidatePosition"){
              tipMsg = renderLiquidationTooltip(tradePosition)
            }
            msg = <div>
                    {msg}       
                    <Tooltip 
                      destroyTooltipOnHide={true} 
                      overlay={tipMsg} 
                      placement='top' 
                      getTooltipContainer={()=>document.querySelector('user-activity')}
                      >
                      <span 
                        style={{color: tradePosition.realisedPnl >=0 ? 'green': 'red', textDecoration: 'underline', cursor: "pointer", textDecorationStyle: 'dashed'}}
                      >
                        ({'Realized PnL: ' + formatPNL(tradePosition.realisedPnl, 2)})
                      </span>
                    </Tooltip>
                  </div>
          }
        }
        
        return {
          msg,
          originMsg,
          ...trade,
        }
      })
      .filter((trade: { msg: any }) => trade.msg);
  }, [trades, getMsg, userPnlOverTime]);

  const isLoading = loading && users[0];

  const filteredUserPositions = [];
  if (userPositions) {
    users.map(user => {
      if(user && userPositions[user.toLowerCase()]) {
        filteredUserPositions.push(...userPositions[user.toLowerCase()].positions)
      }
    })
  }

  const csvLink = useRef();
  const [activityData, setActivityData] = useState([]);
  const [isDownload, setDownload] = useState(false);

  const onDownload = () => {
    const data = [['Timestamp', 'Address', 'Activities']];
    tradesWithMessages.map((trade) => {
      data.push([formatDateTime(trade.timestamp), formatAddress(trade.account), trade.originMsg.trim().replace(/\n|\r/g, "").replace(/  +/g, ' ')]);
    })
    setActivityData(data);
    setDownload(true);
  };

  useEffect(() => {
    if (isDownload) {
      csvLink.current.link.click();
      setDownload(false);
    }
  }, [isDownload])

  return (
    <>
      <div id="user-activity"></div>
      {(tradesWithMessages?.length > 0 || isLoading) && (
        <div
          className="chart-cell"
          style={{ height: "fit-content", "grid-column-start": "span 10" }}
        >
          {filteredUserPositions.length > 0 &&
            <>
              <h3>User Active Positions</h3>
              <table className="traders-table">
                <thead>
                  <th>Address</th>
                  <th>Index</th>
                  <th>Long</th>
                  <th>Size</th>
                  <th>Collateral</th>
                  <th>PnL</th>
                </thead>
                <tbody>
                  {filteredUserPositions.map((position, index) => {
                    return (
                      <tr key={position.indexToken + position.collateralToken + index}>
                        <td>{formatAddress(position.account)}</td>
                        <td>{address_to_symbol[position.indexToken]}</td>
                        <td>{position.isLong ? "Long" : "Short"}</td>
                        <td>{formatPNL(Number(position.size) / 1e30)}</td>
                        <td>{formatPNL(Number(position.collateral) / 1e30)}</td>
                        <td>{formatPNL(Number(position.pnl))}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          }
          <h3 style={{ display: 'flex', marginTop: filteredUserPositions.length > 0 ? '30px' : '0px' }}>
            User Activities
            {tradesWithMessages.length > 0 && (
              <svg onClick={onDownload} style={{ width: '20px', marginLeft: '10px', cursor: 'pointer' }} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"/>
              </svg>
            )}
          </h3>
          {isLoading && <RiLoader5Fill size="3em" className="loader" />}
          {!isLoading &&
            <>
              <table className="traders-table">
                <thead>
                  <th>Timestamp</th>
                  <th>Address</th>
                  <th>Activities</th>
                </thead>
                <tbody>
                  {tradesWithMessages.map((trade, index) => {
                    return (
                      <tr key={trade.timestamp + index}>
                        <td>{formatDateTime(trade.timestamp)}</td>
                        <td>{formatAddress(trade.account)}</td>
                        <td>{trade.msg}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </>
          }
        </div>
      )}
      <CSVLink
        data={activityData}
        filename='user_activities.csv'
        className='hidden'
        ref={csvLink}
        target='_blank'
      />
    </>
  );
}

export default UserActivity;
