import React, { useState, useEffect } from "react";
import {
  RiCheckboxBlankFill,
  RiCheckboxFill,
  RiLoader5Fill,
  RiNewspaperFill,
} from "react-icons/ri";
import Pagination from "../Pagination/Pagination";
import CopyToClipboard from "./CopyToClipboard";
import XnsTooltip from "./XnsTooltip"
import Select from "react-dropdown-select";

const formatPNL = (amount) => {
  if (amount < 0) {
    return `- $${Number((-amount).toFixed(0)).toLocaleString("en-US")}`;
  }
  return `$${Number(amount.toFixed(0)).toLocaleString("en-US")}`;
};

function TopPnLHolders({ pnlLeaders, userPositionsLoading }) {
  const [pnlLeadersLimit, setPnlLeadersLimit] = useState(50);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 1024;

  const [pnlSortOption, setPnlSortOption] = useState({
    key: "pnl",
    asc: false,
  });

  const pageSize = 10;
  const [page, setPage] = useState(1);

  const [pnlOption, setPnlOption] = useState(1);

  return (
    <div
      className="chart-cell"
      style={{
        height: "fit-content",
        gridColumnStart: isMobile ? "span 10" : "span 5",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h3>Top PnL Holders</h3>
        {!userPositionsLoading && pnlLeaders && pnlLeaders.length > 0 && (
          <div
            onClick={() => {
              setPnlOption(3 - pnlOption);
            }}
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {pnlOption === 1 ? (
              <RiCheckboxBlankFill
                size="1.5em"
                style={{ marginRight: "10px" }}
              />
            ) : (
              <RiCheckboxFill size="1.5em" style={{ marginRight: "10px" }} />
            )}
            Show Open PnL after fees
          </div>
        )}
      </div>
      {userPositionsLoading && <RiLoader5Fill size="3em" className="loader" />}
      {!userPositionsLoading && (
        <table className="traders-table">
          <thead>
            <th>No.</th>
            <th>Address</th>
            <th
              style={{
                cursor: "pointer",
                textDecoration: pnlSortOption.key === "size" && "underline",
              }}
              onClick={() => {
                setPnlSortOption({
                  key: "size",
                  asc:
                    pnlSortOption.key === "size" ? !pnlSortOption.asc : false,
                });
              }}
            >
              Current Net Value
            </th>
            <th
              style={{
                cursor: "pointer",
                textDecoration: pnlSortOption.key === "pnl" && "underline",
              }}
              onClick={() => {
                setPnlSortOption({
                  key: "pnl",
                  asc: pnlSortOption.key === "pnl" ? !pnlSortOption.asc : false,
                });
              }}
            >
              Open PnL
            </th>
            <th
              style={{
                cursor: "pointer",
                textDecoration: pnlSortOption.key === "profit" && "underline",
              }}
              onClick={() => {
                setPnlSortOption({
                  key: "profit",
                  asc:
                    pnlSortOption.key === "profit" ? !pnlSortOption.asc : false,
                });
              }}
            >
              Open Profit
            </th>
            <th
              style={{
                cursor: "pointer",
                textDecoration: pnlSortOption.key === "loss" && "underline",
              }}
              onClick={() => {
                setPnlSortOption({
                  key: "loss",
                  asc:
                    pnlSortOption.key === "loss" ? !pnlSortOption.asc : false,
                });
              }}
            >
              Open Loss
            </th>
            <th
              style={{
                cursor: "pointer",
                textDecoration:
                  pnlSortOption.key === "earnedPnL" && "underline",
              }}
              onClick={() => {
                setPnlSortOption({
                  key: "earnedPnL",
                  asc:
                    pnlSortOption.key === "earnedPnL"
                      ? !pnlSortOption.asc
                      : false,
                });
              }}
            >
              All time PnL
            </th>
          </thead>
          <tbody>
            {pnlLeaders
              .sort((a, b) => {
                if (pnlOption === 1) {
                  if (pnlSortOption.key === "pnl") {
                    return pnlSortOption.asc ? a.pnl - b.pnl : b.pnl - a.pnl;
                  }
                  if (pnlSortOption.key === "profit") {
                    return pnlSortOption.asc
                      ? a.profit - b.profit
                      : b.profit - a.profit;
                  }
                  if (pnlSortOption.key === "loss") {
                    return pnlSortOption.asc
                      ? a.loss - b.loss
                      : b.loss - a.loss;
                  }
                  if (pnlSortOption.key === "earnedPnL") {
                    return pnlSortOption.asc
                      ? a.earnedPnL - b.earnedPnL
                      : b.earnedPnL - a.earnedPnL;
                  }
                } else {
                  if (pnlSortOption.key === "pnl") {
                    return pnlSortOption.asc
                      ? a.pnlAfterFee - b.pnlAfterFee
                      : b.pnlAfterFee - a.pnlAfterFee;
                  }
                  if (pnlSortOption.key === "profit") {
                    return pnlSortOption.asc
                      ? a.profitAfterFee - b.profitAfterFee
                      : b.profitAfterFee - a.profitAfterFee;
                  }
                  if (pnlSortOption.key === "loss") {
                    return pnlSortOption.asc
                      ? a.lossAfterFee - b.lossAfterFee
                      : b.lossAfterFee - a.lossAfterFee;
                  }
                  if (pnlSortOption.key === "earnedPnL") {
                    return pnlSortOption.asc
                      ? a.earnedPnLAfterFee - b.earnedPnLAfterFee
                      : b.earnedPnLAfterFee - a.earnedPnLAfterFee;
                  }
                }
                if (pnlSortOption.key === "size") {
                  return pnlSortOption.asc
                    ? a.netValue - b.netValue
                    : b.netValue - a.netValue;
                }
                return b.pnl - a.pnl;
              })
              .slice(0, pnlLeadersLimit)
              // .slice(pageSize * (page - 1), pageSize * page)
              .map((item, index) => {
                return (
                  <tr key={item.account}>
                    <td>{index + 1}</td>
                    {/* <td>{pageSize * (page - 1) + index + 1}</td> */}
                    <td>
                      <a
                        href={`https://phatty.io/profile/${item.account}/portfolio`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        <XnsTooltip account={item.account} xns={item.xns} ens={item.ens} pns={item.pns}></XnsTooltip>
                      </a>
                      <CopyToClipboard text={item.account} />
                    </td>
                    <td style={{ textAlign: "right" }}>
                      $
                      {Number(item.netValue.toFixed(0)).toLocaleString("en-US")}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatPNL(pnlOption === 1 ? item.pnl : item.pnlAfterFee)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatPNL(
                        pnlOption === 1 ? item.profit : item.profitAfterFee
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatPNL(
                        pnlOption === 1 ? item.loss : item.lossAfterFee
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatPNL(
                        pnlOption === 1
                          ? item.earnedPnL
                          : item.earnedPnLAfterFee
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {pnlLeaders.length > pnlLeadersLimit && (
        <div
          style={{
            marginTop: "10px",
            textAlign: "center",
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() => setPnlLeadersLimit(pnlLeadersLimit + 50)}
        >
          Load More
        </div>
      )}
      {/* <Pagination
        className="pagination-bar"
        currentPage={page}
        totalCount={pnlLeaders.length}
        pageSize={pageSize}
        onPageChange={(page) => setPage(page)}
      /> */}
      <div style={{ textAlign: "right" }}>
        (The fee toggle for All Time PnL only incudes open position)
      </div>
    </div>
  );
}

export default TopPnLHolders;
