function createHttpError(code, message) {
  const error = new Error(message);
  error.code = code;
  return error;
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function toReadable(ts) {
  return new Date(ts * 1000)
    .toISOString()
    .replace("T", " ")
    .replace(".000Z", "");
}

function formatAddress(address) {
  const length = address.length;
  if (length < 20) {
    return address;
  }

  return address.slice(0, 6) + "..." + address.slice(length - 4, length);
}

module.exports = {
  sleep,
  toReadable,
  createHttpError,
  formatAddress,
};
