import React, { useMemo, useState } from "react";
import moment from "moment";
import cx from "classnames";
import { RiSearchLine } from "react-icons/ri";
import DateRangeSelect from "../components/DateRangeSelect";

import {
  useLastSubgraphBlock,
  useLastBlock,
  useAllTradersXnsNames,
  useUserPositions,
  useAllDecreasePositions,
  useAllIncreasePositions,
  useAllUpdatePositions,
  useAllLiquidatedPositions,
  useAllClosePositions,
} from "../dataProvider";
import OpenInterest from "../components/Traders/OpenInterest";
import UserActivity from "../components/Traders/UserActivity";
import TopPnLHolders from "../components/Traders/TopPnLHolders";
import TopTraders from "../components/Traders/TopTraders";

const NOW = Math.floor(Date.now() / 1000);

// If there are multiple positions in time,
// it is possible to infer the current point based on the known points before and after it.
// Structure: [leftPos, needInferPos, rightPos]
function inferPositionActions(positions) {
  for (let posIndex = 0; posIndex < positions.length; posIndex++) {
    const position = positions[posIndex];
    const { actions, key } = position;
    if (Array.isArray(actions) && actions.length) {
      let arr = [position];
      let l = posIndex - 1;
      while (l >= 0) {
        // Identified position on the left side
        if (positions[l]["key"] === key) {
          arr.unshift(positions[l]);
          break;
        }
        l--;
      }
      if (!Array.isArray(arr[0].actions)) {
        // structure [leftPos, needInferPos]
        let leftSuccess = true;
        const leftPos = arr.shift();
        const { __typename, action, size } = leftPos;
        let nextSize =
          __typename === "LiquidatedPosition" || action === "ClosePosition"
            ? 0
            : size;
        let nextPos = arr.shift();
        while (nextPos) {
          const { sizeDelta, __typename, actions } = nextPos;
          if (__typename === "DecreasePosition") {
            nextSize = nextSize - sizeDelta;
          } else if (__typename === "IncreasePosition") {
            nextSize = nextSize + sizeDelta;
          } else {
            leftSuccess = false;
            console.log("nextPos : ", nextPos);
            console.log("__typename is not support: ", __typename);
            break;
          }

          const actionIndex = actions?.findIndex(
            (action) => Math.floor(action.size) === Math.floor(nextSize)
          );
          if (actionIndex === -1) {
            const {
              size: leftPosSize = 0,
              sizeDelta: leftPosSizeDelta,
              action: leftPosAction,
              __typename: leftPosTypename,
            } = leftPos;
            const {
              sizeDelta: nextPosSizeDelta,
              collateralDelta: nextPosCollateralDelta,
              __typename: nextPosTypename,
            } = nextPos;
            // Infer that nextPos is an entry position.
            if (
              (leftPosAction === "ClosePosition" ||
                leftPosTypename === "LiquidatedPosition") &&
              nextPosTypename === "IncreasePosition"
            ) {
              nextPos.size = nextPosSizeDelta;
              nextPos.action = "UpdatePosition";
              nextPos.actions = undefined;
              break;
            }
            // Infer that nextPos is a closing position
            else if (
              nextPosCollateralDelta === 0 &&
              nextPosTypename === "DecreasePosition" &&
              Math.floor(leftPosSize) === Math.floor(nextPosSizeDelta)
            ) {
              nextPos.size = nextPosSizeDelta;
              nextPos.action = "ClosePosition";
              nextPos.actions = undefined;
              break;
            }
            leftSuccess = false;
            // console.log("leftPos nextPos : ", leftPos, nextPos);
            // console.log("can't find nextSize index in actions: ", nextSize);
            break;
          }
          nextPos.action = actions[actionIndex].__typename;
          nextPos.realisedPnl = actions[actionIndex].realisedPnl;
          nextPos.markPrice = actions[actionIndex].markPrice;
          nextPos.size = actions[actionIndex].size;
          nextPos.actions = undefined;

          nextPos = arr.shift();
        }
        if (leftSuccess) {
          continue;
        } else {
          // If inference from the left side is unsuccessful, we can attempt it from the right side.
          // console.log(`Can't deduce positions by left postion:`, position);
        }
      }

      // Identified position on the right side
      arr = [position];
      let r = posIndex + 1;
      while (r < positions.length) {
        // structure [...needInferPos, rightPos]
        if (positions[r]["key"] === key) {
          arr.push(positions[r]);
          if (!Array.isArray(positions[r].actions)) {
            break;
          }
        }
        r++;
      }
      if (Array.isArray(arr[arr.length - 1].actions)) {
        console.log(`Con't deduce positions`, arr);
        continue;
      }
      let rightSuccess = true;
      const rightPos = arr.pop();
      const { __typename, action, size, sizeDelta } = rightPos;
      let prevSize = 0;
      if (__typename === "LiquidatedPosition" || action === "ClosePosition") {
        prevSize = size;
      } else if (__typename === "DecreasePosition") {
        prevSize = prevSize + sizeDelta;
      } else if (__typename === "IncreasePosition") {
        prevSize = prevSize - sizeDelta;
      }
      let prevPos = arr.pop();
      while (prevPos) {
        const { sizeDelta, __typename, actions } = prevPos;
        const actionIndex = actions?.findIndex(
          (action) => Math.floor(action.size) === Math.floor(prevSize)
        );
        if (actionIndex === -1) {
          rightSuccess = false;
          // console.log("prevPos rightPos: ", prevPos, rightPos);
          // console.log("can't find prevSize index in actions: ", prevSize);
          break;
        }
        prevPos.action = actions[actionIndex].__typename;
        prevPos.markPrice = actions[actionIndex].markPrice;
        prevPos.realisedPnl = actions[actionIndex].realisedPnl;
        prevSize = prevPos.size = actions[actionIndex].size;
        prevPos.actions = undefined;

        if (__typename === "DecreasePosition") {
          prevSize = prevSize + sizeDelta;
        } else if (__typename === "IncreasePosition") {
          prevSize = prevSize - sizeDelta;
        } else {
          rightSuccess = false;
          console.log("prevPos : ", prevPos);
          console.log("__typename is not support: ", __typename);
          break;
        }

        prevPos = arr.pop();
      }
      // if (!rightSuccess) {
      //   console.log(`Can't deduce positions by right postion:`, position);
      // }
    }
  }
}

const DEFAULT_GROUP_PERIOD = 86400;

const dateRangeOptions = [
  {
    label: "Last Month",
    id: 1,
  },
  {
    label: "Last 2 Months",
    id: 2,
    isDefault: true,
  },
  {
    label: "Last 3 Months",
    id: 3,
  },
  {
    label: "All time",
    id: 4,
  },
];

function Traders() {
  const [lastSubgraphBlock, , lastSubgraphBlockError] = useLastSubgraphBlock();
  const [lastBlock] = useLastBlock();

  const isObsolete = useMemo(
    function () {
      return (
        lastSubgraphBlock &&
        lastBlock &&
        lastBlock.timestamp - lastSubgraphBlock.timestamp > 3600
      );
    },
    [lastSubgraphBlock, lastBlock]
  );

  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD);

  const [dataRange, setDataRange] = useState({
    fromValue: moment().subtract(dateRangeOptions[1].id, "month").toDate(),
    toValue: null,
  });

  const params = useMemo(() => {
    const from = dataRange.fromValue
      ? Math.floor(+new Date(dataRange.fromValue) / 1000)
      : undefined;
    const to = dataRange.toValue
      ? Math.floor(+new Date(dataRange.toValue) / 1000)
      : NOW;
    return {
      from,
      to,
      groupPeriod,
    };
  }, [dataRange, groupPeriod]);

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDataRange({ fromValue: start, toValue: end });
  };

  const [searchKey, setSearchKey] = useState("");

  const [decreasePositions, decreasePositionsLoading] =
    useAllDecreasePositions(params);
  const [increasePositions, increasePositionsLoading] =
    useAllIncreasePositions(params);
  const [updatePositions, updatePositionsLoading] =
    useAllUpdatePositions(params);
  const [liquidatedPositions, liquidatedPositionsLoading] =
    useAllLiquidatedPositions(params);
  const [closePositions, closePositionsLoading] = useAllClosePositions(params);

  const [allTradersAddress, setAllTradersAddress] = useState([]);

  const [allTradersXnsNames, allTradersXnsNamesLoading] = useAllTradersXnsNames(
    { users: allTradersAddress }
  );

  const userPnlOverTime = useMemo(() => {
    if (!decreasePositions || decreasePositionsLoading) return [];
    if (!increasePositions || increasePositionsLoading) return [];
    if (!updatePositions || updatePositionsLoading) return [];
    if (!liquidatedPositions || liquidatedPositionsLoading) return [];
    if (!closePositions || closePositionsLoading) return [];

    const updatePositionsMap = new Map();
    updatePositions.forEach((pos) => {
      const { key, timestamp } = pos;
      const uuid = `${key}${timestamp}`;
      let actions = updatePositionsMap.get(uuid);
      if (!actions) {
        actions = [];
        updatePositionsMap.set(uuid, actions);
      }
      actions.push(pos);
    });

    const closePositionsMap = new Map();
    closePositions.forEach((pos) => {
      const { key, timestamp } = pos;
      const uuid = `${key}${timestamp}`;
      let actions = closePositionsMap.get(uuid);
      if (!actions) {
        actions = [];
        closePositionsMap.set(uuid, actions);
      }
      actions.push(pos);
    });

    const accountsMap = new Map();

    const positions = decreasePositions
      .concat(increasePositions)
      .concat(liquidatedPositions);

    for (const position of positions) {
      const { account, key, timestamp, __typename } = position;
      let accountItem = accountsMap.get(account);
      if (!accountItem) {
        accountItem = {
          needInferred: false,
          account: account,
          realisedPnl: 0,
          increaseAmount: 0,
          decreaseAmount: 0,
          positions: [],
        };
        accountsMap.set(account, accountItem);
      }
      const uuid = `${key}${timestamp}`;
      if (__typename === "IncreasePosition") {
        position.actions = updatePositionsMap.get(uuid);
      } else if (__typename === "DecreasePosition") {
        const closeActions = closePositionsMap.get(uuid);
        let isCloseAction = false;
        if (closeActions) {
          const { collateralDelta, sizeDelta } = position;
          for (let closeAction of closeActions) {
            const { realisedPnl, size, __typename } = closeAction;
            if (collateralDelta === 0 && size === sizeDelta) {
              position.action = __typename;
              position.realisedPnl = realisedPnl;
              position.size = size;
              position.actions = undefined;
              isCloseAction = true;
              break;
            }
          }
        }
        if (!isCloseAction) {
          position.actions = updatePositionsMap.get(uuid) || [];
        }
      }
      if (Array.isArray(position.actions) && position.actions.length === 1) {
        const { realisedPnl, size, __typename, markPrice } =
          position.actions[0];
        position.action = __typename;
        position.realisedPnl = realisedPnl;
        position.markPrice = markPrice;
        position.size = size;
        position.actions = undefined;
      }
      if (Array.isArray(position.actions)) {
        accountItem.needInferred = true;
      }
      accountItem.positions.push(position);
    }

    for (const accountItem of accountsMap.values()) {
      const { positions, needInferred } = accountItem;
      positions.sort((a, b) => a.timestamp - b.timestamp);
      if (needInferred) {
        inferPositionActions(positions);
      }
      let totalRealisedPnl = 0;
      let totalIncreaseAmount = 0;
      let totalDecreaseAmount = 0;
      positions.forEach((position, posIndex) => {
        const { __typename, realisedPnl = 0, sizeDelta = 0, action } = position;
        // realisedPnl is cumulatively calculated, the cumulative value of updatePosition.
        if (action === "ClosePosition") {
          const { realisedPnl = 0 } = position;
          totalRealisedPnl += realisedPnl;
        }
        if (__typename === "LiquidatedPosition") {
          const { loss = 0 } = position;
          position.realisedPnl = -loss;
          totalRealisedPnl += position.realisedPnl;
        }
        // Calculate trading volume
        if (__typename === "IncreasePosition") {
          totalIncreaseAmount += sizeDelta;
        }
        if (__typename === "DecreasePosition") {
          totalDecreaseAmount += sizeDelta;
        }
      });
      accountItem.realisedPnl = totalRealisedPnl;
      accountItem.increaseAmount = totalIncreaseAmount;
      accountItem.decreaseAmount = totalDecreaseAmount;
    }
    const result = Array.from(accountsMap.values());
    setAllTradersAddress(Array.from(accountsMap.keys()));
    updatePositionsMap.clear();
    closePositionsMap.clear();
    accountsMap.clear();
    return result.sort((a, b) => b.realisedPnl - a.realisedPnl);
  }, [
    decreasePositions,
    decreasePositionsLoading,
    increasePositions,
    increasePositionsLoading,
    updatePositions,
    updatePositionsLoading,
    liquidatedPositions,
    liquidatedPositionsLoading,
    closePositions,
    closePositionsLoading,
  ]);

  const [userPositions, userPositionsLoading] = useUserPositions(params);
  const pnlLeaders = useMemo(() => {
    if (!userPositions || userPositionsLoading) return [];
    if (!userPnlOverTime || userPnlOverTime.length == 0) return [];

    const result = Object.values(userPositions).map((item) => ({
      ...item,
      earnedPnL: item.pnl,
      earnedPnLAfterFee: item.pnlAfterFee,
    }));
    for (let i = 0; i < userPnlOverTime.length; i++) {
      const user = userPnlOverTime[i].account;
      const index = result.findIndex((item) => item.account == user);
      if (index !== -1) {
        result[index].earnedPnL =
          (result[index].earnedPnL || 0) + userPnlOverTime[i].realisedPnl;
        result[index].earnedPnLAfterFee =
          (result[index].earnedPnLAfterFee || 0) +
          userPnlOverTime[i].realisedPnl;
      } else {
        result.push({
          account: user,
          netValue: 0,
          pnl: 0,
          profit: 0,
          loss: 0,
          pnlAfterFee: 0,
          profitAfterFee: 0,
          lossAfterFee: 0,
          earnedPnL: userPnlOverTime[i].realisedPnl,
          earnedPnLAfterFee: userPnlOverTime[i].realisedPnl,
        });
      }
    }
    return result;
  }, [userPositions, userPositionsLoading, userPnlOverTime]);

  const allTraders = useMemo(
    function () {
      if (allTradersXnsNamesLoading) return [];
      if (userPnlOverTime.length === 0) {
        return [];
      }
      const result = userPnlOverTime.map((accountItem) => {
        const { account, increaseAmount = 0, decreaseAmount = 0 } = accountItem;
        const xnsNames = allTradersXnsNames[account] || {}
        return {
          account,
          increaseAmount,
          decreaseAmount,
          ens: xnsNames.ens,
          pns: xnsNames.pns,
          xns: xnsNames.xns,
        };
      });
      return result;
    },
    [userPnlOverTime, allTradersXnsNames, allTradersXnsNamesLoading]
  );

  const traders = useMemo(() => {
    if (allTraders.length === 0) return [];
    if (!pnlLeaders || pnlLeaders.length === 0) return [];

    return allTraders.map((item) => ({
      ...item,
      amount: item.increaseAmount + item.decreaseAmount,
      earnedPnL:
        pnlLeaders.find(
          (leader) =>
            leader.account.toLowerCase() === item.account.toLowerCase()
        )?.earnedPnL || 0,
      earnedPnLAfterFee:
        pnlLeaders.find(
          (leader) =>
            leader.account.toLowerCase() === item.account.toLowerCase()
        )?.earnedPnLAfterFee || 0,
    }));
  }, [allTraders, pnlLeaders]);

  return (
    <div className="Home">
      <div className="page-title-section">
        <div className="page-title-block">
          <h1>Traders Data</h1>
          <p>Beta only - Data might be delayed or inaccurate</p>
          {lastSubgraphBlock && lastBlock && (
            <p className={cx("page-description", { warning: isObsolete })}>
              {isObsolete && "Data is obsolete. "}
              Updated {moment(lastSubgraphBlock.timestamp * 1000).fromNow()}
              &nbsp;at block{" "}
              <a
                rel="noreferrer"
                target="_blank"
                href={`https://otter.pulsechain.com/block/${lastSubgraphBlock.number}`}
              >
                {lastSubgraphBlock.number}
              </a>
            </p>
          )}
          {lastSubgraphBlockError && (
            <p className="page-description warning">
              Subgraph data is temporarily unavailable.
            </p>
          )}
        </div>
        <div className="form">
          <DateRangeSelect
            options={dateRangeOptions}
            startDate={dataRange.fromValue}
            endDate={dataRange.toValue}
            onChange={onDateRangeChange}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "10px",
        }}
      >
        Search by address:
        <div style={{ position: "relative" }}>
          <textarea
            className="chart-cell"
            type="text"
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            style={{
              marginLeft: "10px",
              outline: "none",
              padding: "10px",
              paddingLeft: "30px",
              width: "400px",
              border: "1px solid #4e5168",
            }}
            placeholder="Input one or more addresses here ..."
          />
          <RiSearchLine
            style={{
              position: "absolute",
              left: "20px",
              top: "10px",
            }}
            size="1em"
          />
        </div>
      </div>
      <div className="chart-grid">
        <OpenInterest userPositions={userPositions} />
        <TopPnLHolders
          pnlLeaders={pnlLeaders.filter((a) => {
            const keys = searchKey.replace(" ", "").split("\n");
            for (let i = 0; i < keys.length; i++) {
              if (
                a.account.toLowerCase().includes(keys[i].toLowerCase()) ||
                (a.xns || "").toLowerCase().includes(keys[i].toLowerCase())
              ) {
                return true;
              }
            }
            return false;
          })}
          userPositionsLoading={userPositionsLoading}
        />
        <TopTraders
          traders={traders.filter((a) => {
            const keys = searchKey.replace(" ", "").split("\n");
            for (let i = 0; i < keys.length; i++) {
              if (
                a.account.toLowerCase().includes(keys[i].toLowerCase()) ||
                (a.xns || "").toLowerCase().includes(keys[i].toLowerCase())
              ) {
                return true;
              }
            }
            return false;
          })}
          allTradersLoading={traders.length === 0}
        />
        {searchKey && (
          <UserActivity
            users={traders
              .filter((a) => {
                const keys = searchKey.replace(" ", "").split("\n");
                for (let i = 0; i < keys.length; i++) {
                  if (
                    a.account.toLowerCase().includes(keys[i].toLowerCase()) ||
                    (a.xns || "").toLowerCase().includes(keys[i].toLowerCase())
                  ) {
                    return true;
                  }
                }
                return false;
              })
              .map((item) => item.account)}
            userPnlOverTime={userPnlOverTime}
            userPositions={userPositions}
            params={params}
          />
        )}
      </div>
    </div>
  );
}

export default Traders;
