export const PULSE = 369;

export const addresses = {
  [PULSE]: {
    PHAME: "0x8854bC985fB5725F872c8856bEA11B917cAEb2fE",
    WPLS: "0xa1077a294dde1b09bb078844df40758a5d0f9a27",
    PLSX: "0x95b303987a60c71504d99aa1b13b4da07b0790ab",
    HEX: "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39",
    // HDRN: "0x3819f64f282bf135d62168c1e513280daf905e06",
    // MAXI: "0xe0d1bd019665956945043c96499c6414cfc300a9",
    // XEN: "0x06450dee7fd2fb8e39061434babcfc05599a6fb8",
    // LOAN: "0x4f7fcdb511a25099f870ee57c77f7db2561ec9b6",
    WETH: "0x02dcdd04e3f455d838cd1249292c58f3b79e3c3c",
    WBTC: "0xb17d901469b9208b17d916112988a3fed19b5ca1",
    USDC: "0x15d38573d2feeb82e7ad5187ab8c1d52810b1f07",
    USDT: "0x0cb6f5a34ad42ec934882a05265a7d5f59b51a2f",
    DAI: "0xefd766ccb38eaf1dfd701853bfce31359239f305",
    RewardReader: "0x0000000000000000000000000000000000000000",
    PHLP: "0xf8bba8b1b1a05992b18051e4e79415364cbf4539",
    PhlpManager: "0x268976b5B57A75A0a3e25Bf1d567BB72418b2925",
    PhamousUiDataProvider: '0x4799Dc49bCE225f5Ee1256c196819DD7d77707B5',
    AddressesProvider: '0xb29eA150c870Ea830d9890d94f326CB1b273cE8C',
  },
};

export function getAddress(chainId, key) {
  if (!chainId in addresses) {
    throw new Error(`Unknown chain ${chainId}`);
  }
  if (!(key in addresses[chainId])) {
    throw new Error(`Unknown address key ${key}`);
  }
  return addresses[chainId][key];
}
