import React, { useMemo } from "react";
import Tooltip from "rc-tooltip";
import { formatAddress } from "../../utils";

import "../ToolTip/index.css";

export default function XnsTooltip({ ens, pns, xns, account }) {
  const tipMsg = useMemo(() => {
    return (
      <div>
        {ens && (
          <>
            ens: {ens}
            <br />
          </>
        )}
        {pns && (
          <>
            pns: {pns}
            <br />
          </>
        )}
        Address: {formatAddress(account)}
        <br />
      </div>
    );
  }, [ens, pns, account]);
  if (!xns) {
    return <span>{formatAddress(xns || account)}</span>;
  }
  return (
    <Tooltip destroyTooltipOnHide={true} overlay={tipMsg} placement="top">
      <span>{formatAddress(xns || account)}</span>
    </Tooltip>
  );
}
